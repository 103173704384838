import React, { useState } from "react"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Container, Row, Col } from "react-bootstrap"
import "./Testimonials.scss"
import ReactMarkdown from "react-markdown"
import Slider from "react-slick"
import he from "he"

const Testimonials = props => {
  const[readMore, setReadMore] = useState(false);

  var settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <div>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.div
            className="testimonial-banner"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={contentItemStagger}
          >
            <Container className="testimonials-section">
              <Row>
                <Col>
                  <div>
                    <motion.h2 variants={contentItem}>{props?.Title}</motion.h2>
                    <div>
                      <Slider {...settings}>
                        {props.testimonials_data.map(data => {

                        var strip_tags = data.Content.replace(
                          /<[^>]+>/g,
                          " "
                        ).replace(/(<br \/>\s*)+$/g, " ")
                        var decodedStripedHtml = strip_tags && he.decode(strip_tags)
                          return(
                          <motion.div
                            className="testimonials-content"
                            variants={contentItem}
                          >
                            {/* <ReactMarkdown
                              source={data.Content}
                              allowDangerousHtml
                              className="content"
                            /> */}
                            
                            <p className="content">
                              {decodedStripedHtml.length > 315 ? readMore ? decodedStripedHtml : decodedStripedHtml.substring(0, 315)+"... " : decodedStripedHtml} 

                              {decodedStripedHtml.length > 315 && (
                                readMore ? <a href="javascript:;" onClick={() => setReadMore(false)}>Read Less</a> 
                                  : <a href="javascript:;" onClick={() => setReadMore(true)}>Read More</a>
                                )                                
                              }
                            </p>

                            <img
                              className="agent-testimonial-image"
                              src={data?.Image?.url}
                              alt={data.Name+" - DNG Estate Agents"}
                            />
                            <p className="company-name">{data.Name}</p>
                            {/* <p className="sublime-text">{data.Text}</p> */}
                          </motion.div>
                          )
                        }
                        )}
                      </Slider>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </motion.div>
        )}
      </InView>
    </div>
  )
}

export default Testimonials
