import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
//import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/DNGResidential_1.jpg"
import Image2 from "../../../images/DNGResidential_2.jpg"
import Image3 from "../../../images/DNGResidential_3.jpg"
import Image4 from "../../../images/DNGResidential_4.jpg"
import contactImg1 from "../../../images/analysis_member.jpg"
import "./ExploreGrid.scss"
import ReactMarkdown from "react-markdown"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import Youtube from "../../SearchResults/Youtube/Youtube"
import moment from "moment"
import PlayVideo from "../../PlayVideo/PlayVideo"
import OpinionListGridVideo from "../../Home/Opinion/OpinionList/OpinionListGridVideo"
import he from "he"
import ShowMoreText from "react-show-more-text"

const ExploreGrid = props => {
  const [isPlay, setIsPlay] = useState(false)
  const [isIndex, setIndex] = useState(false)

  const playVideoHandler = i => {
    setIsPlay(true)
    setIndex(i)
  }

  return (
    <div className="videos-block explore-grid opinion">
      <Container>
        <Row>
          <Col sm="12" lg="8" className="explore-top-content">
            {props?.Content && (
              <motion.div variants={fadeInFromLeft}>
                <ReactMarkdown source={props?.Content} allowDangerousHtml />
              </motion.div>
            )}
          </Col>
        </Row>
        <Row>
          {props.Add_Explore_Grid?.map((item, index) => {
            var strip_tags = (item?.Content ?? "").replace(/<[^>]+>/g, " ").replace(
              /(<br \/>\s*)+$/g,
              " "
            );
            var decodedStripedHtml = strip_tags && he.decode(strip_tags)
            return (
              <>
                <Col md="4" lg="4">
                  <div className="advantage-item">
                    <picture>
                      {item.Video_Url && (
                        <div className="btn-wrapper">
                          <button
                            type="button"
                            onClick={() => playVideoHandler(index)}
                          >
                            <i className="icon-play"></i>
                          </button>
                        </div>
                      )}
                      <img
                        src={item.Image?.url}
                        alt={
                          item.Image?.alternativeText
                            ? item.Image?.alternativeText
                            : item.Title + " - DNG Estate Agents"
                        }
                      />
                    </picture>
                    <div className="advantage-info">
                      {/* <span className="date-text">{moment(item?.Date).format('DD MMMM YYYY')}</span> */}
                      <h3 className="max">
                        <Link to={item.URL}>{item.Title}</Link>
                      </h3>
                      {/* <ReactMarkdown source={item?.Content} allowDangerousHtml /> */}
                      {item.Content && (
                        <ShowMoreText
                          /* Default options */
                          lines={4}
                          more="Show more"
                          less="Show less"
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          // onClick={executeOnClick}
                          expanded={false}
                          // width={280}
                          truncatedEndingComponent={"... "}
                        >
                          {decodedStripedHtml}
                        </ShowMoreText>
                      )}
                    </div>
                  </div>
                  {index === isIndex && (
                    <PlayVideo
                      isOpen={isPlay}
                      stopPlay={setIsPlay}
                      videoId={item.Video_Url}
                    />
                  )}
                </Col>
              </>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default ExploreGrid
