import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
// Image
import opinionImg1 from "../../../images/opinion-img-1.jpg"
import opinionImg2 from "../../../images/opinion-img-2.jpg"
import opinionImg3 from "../../../images/opinion-img-3.jpg"
import opinionImg4 from "../../../images/opinion-img-4.jpg"
// SCSS
import "./opinion.scss"
import OpinionList from "./OpinionList/OpinionList"
import OpinionListGrid from "./OpinionList/OpinionListGrid"
import ReactMarkdown from "react-markdown"
// //import ScrollAnimation from "react-animate-on-scroll"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import PlayVideo from "../../PlayVideo/PlayVideo"
import { NEWS_PAGE_URL } from "@Components/common/site/constants"
import OpinionListGridCommunity from "./OpinionList/OpinionListGridCommunity"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const Community = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig(publicationState: LIVE) {
          Chairman_Name
          Chairman_Position
          Chairman_Image {
            alternativeText
            url
          }
        }
        communityCategories {
          Category_Name
        }
        communities(
          sort: "Date:desc"
          publicationState: LIVE
          where: { Publish: true }
        ) {
          id
          imagetransforms
          community_categories {
            Category_Name
          }
          URL
          Video_Url
          Title
          Publish
          Meta_Title
          Meta_Description
          Date
          Banner_Content
          Tail_Image {
            alternativeText
            url
          }
          Add_Content_Block {
            Content
            Add_Image {
              Caption
              Image {
                alternativeText
                url
              }
            }
          }
        }
      }
    }
  `)

  const [communities, setcommunities] = useState(data.glstrapi?.communities)
  const [filter, setFilter] = useState("All")
  const clickFilter = e => {
    setFilter(e)
    var arr = []
    data.glstrapi?.communities
      .filter(list =>
        list.community_categories.map(c => c.Category_Name).includes(e)
      )
      .map((item, index) => {
        arr.push(item)
      })
    setcommunities(e === "All" ? data.glstrapi?.communities : arr)
  }
  var names = data.glstrapi?.communityCategories
  const [isPlay, setIsPlay] = useState(false)

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  const postsPerPage = 6
  let arrayForHoldingPosts = []
  // const [] = useState(0);
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(6)
  const loopWithSlice = (start, end) => {
    const slicedPosts = communities?.slice(0, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    loopWithSlice(
      postsToShow?.length > 12 ? next : 12,
      postsToShow?.length > 12 ? next + postsPerPage : 12 + postsPerPage
    )
    setNext(postsToShow?.length > 12 ? next + postsPerPage : 12 + postsPerPage)
  }

  useEffect(() => {
    loopWithSlice(0, 12)
    setNext(6)
  }, [filter])

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className={"opinion newspage analysis-block"}
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col md="12" lg="12">
                {props?.Title && (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown source={props?.Title} allowDangerousHtml />
                  </motion.div>
                )}
              </Col>
              <Col md="12" lg="6">
                {props.Content && (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown source={props?.Content} allowDangerousHtml />
                  </motion.div>
                )}
              </Col>
              <Col md="12" lg="6">
                <div className="dealer-wrap">
                  <div className="img-wrap">
                    <img
                      src={data.glstrapi?.globalConfig?.Chairman_Image?.url}
                      alt={
                        data.glstrapi?.globalConfig?.Chairman_Name +
                        " - DNG Estate Agents"
                      }
                    />
                  </div>
                  <div className="dealer-right">
                    <span className="dealer-name">
                      {data.glstrapi?.globalConfig?.Chairman_Name}
                    </span>
                    <span className="dealer-position">
                      {data.glstrapi?.globalConfig?.Chairman_Position}
                    </span>
                  </div>
                </div>
              </Col>

              <Col sm={12}>
                <motion.div
                  className="fiter-news-categories"
                  variants={fadeInFromLeft}
                >
                  <ul>
                    <li
                      onClick={() => clickFilter("All")}
                      className={
                        filter === "All"
                          ? "all-news active-category list-tab"
                          : "all-news list-tab"
                      }
                    >
                      All Insights
                    </li>
                    {names.map(list => (
                      <li
                        onClick={() => clickFilter(list.Category_Name)}
                        className={
                          filter === list.Category_Name
                            ? "active-category list-tab"
                            : "list-tab"
                        }
                      >
                        {list.Category_Name}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              </Col>
              <Col sm={12}>
                <OpinionListGridCommunity
                  filter={filter}
                  communities={postsToShow}
                />
              </Col>
            </Row>
          </Container>
          {postsToShow?.length !== communities?.length && (
            <Container>
              <Row>
                <Col md="12">
                  <div className="review-load">
                    <button
                      className="btn-pagination btn btn-primary"
                      onClick={handleShowMorePosts}
                    >
                      Load more
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </motion.section>
      )}
    </InView>
  )
}

export default Community
