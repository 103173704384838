import React from "react"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Container, Row, Col } from "react-bootstrap"
import "./TextStyling.scss"
import ReactMarkdown from "react-markdown"

const StaticSitePage = props => {
  const title = props.Title
  const content = props.TextContent
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="terms-and-condition"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container className="terms-container">
            <Row>
              <Col>
                <motion.h1>{title}</motion.h1>
                <motion.div className="terms-content">
                  <ReactMarkdown
                    source={content}
                    allowDangerousHtml
                    className=""
                  />
                </motion.div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default StaticSitePage
