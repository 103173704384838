import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { graphql } from "gatsby"
import { useMatch } from "@reach/router"
import PageModules from "@Components/modules"
import Home from "@Components/home"
import Team from "@Components/team"
import Offices from "@Components/offices"
import BlogDetails from "@Components/BlogDetails/BlogDetails"
import CommunityDetails from "@Components/CommunityDetails/CommunityDetails"
import CareersDetails from "@Components/CareersDetails/CareersDetails"
import ExpressesDetails from "@Components/ExpressesDetails/ExpressesDetails"
import ResearchDetails from "@Components/ResearchDetails/ResearchDetails"
import TeamDetails from "@Components/TeamDetails/TeamDetails"
import OfficeDetails from "@Components/OfficeDetails/OfficeDetails"
import PastAuctions from "@Components/PastAuctions"
import UpcomingAuctions from "@Components/UpcomingAuctions"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "../scss/global.scss"
import "../scss/grunticon.scss"
import {
  NEWS_PAGE_URL,
  Community_Page_Url,
  Careers_Page_Url,
  Expresses_Page_Url,
  Research_Page_Url,
  Office_Page_Url,
  Team_Page_Url,
} from "@Components/common/site/constants"
import PopularSearch from "@Components/StaticPageTwo/PopularSearches/PopularSearch"

const DefaultTemplate = props => {

if(typeof window !== "undefined" && window){  
window.__lc = window.__lc || {};
window.__lc.license = 9199905;
(function () {
var lc = document.createElement('script');
lc.type = 'text/javascript';
lc.async = true;
lc.defer = true;
lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') +
'cdn.livechatinc.com/tracking.js';
var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(lc, s);
})();

if(typeof window !== "undefined"&&window){
  var s = document?.getElementById("chat-widget-container")
  var t = document?.getElementById("livechat-eye-catcher")
  
  if(s){
    s.style.display = 'block'
  }
  if(t){
    t.style.display = 'block'
  }
}
}

  const blogDetails = useMatch(NEWS_PAGE_URL.alias + "/:item/")
  const communityDetails = useMatch(Community_Page_Url.alias + "/:item/")
  const careersDetails = useMatch(Careers_Page_Url.alias + "/:item/")
  const researchDetails = useMatch(Research_Page_Url.alias + "/:item/")
  const teamDetails = useMatch(Team_Page_Url.alias + "/:item/")
  const officeDetails = useMatch(Office_Page_Url.alias + "/:item/")
  const expressesDetails = useMatch(Expresses_Page_Url.alias + "/:item/")
  const Modules = props.data.glstrapi?.page?.Modules
  const Page = props.data.glstrapi?.page
  const Alias = props?.pageContext?.Alias

  var layout = "Search_Result_Page"

  // const contact = props.data.glstrapi?.contact
  if (blogDetails !== null) {
    var URL = blogDetails.item
    var page_url = NEWS_PAGE_URL.alias.split("/")
    return (
      <>
        <BlogDetails slug={URL} alias={page_url.pop()} />
      </>
    )
  } else if (communityDetails !== null) {
    var URL = communityDetails.item
    var page_url = Community_Page_Url.alias.split("/")
    return (
      <>
        <CommunityDetails slug={URL} alias={page_url.pop()} />
      </>
    )
  } else if (careersDetails !== null) {
    var URL = careersDetails.item
    var page_url = Careers_Page_Url.alias.split("/")
    return (
      <>
        <CareersDetails slug={URL} alias={page_url.pop()} />
      </>
    )
  } else if (teamDetails !== null) {
    var URL = teamDetails.item
    var page_url = Team_Page_Url.alias.split("/")
    return (
      <>
        <TeamDetails slug={URL} alias={page_url.pop()} />
      </>
    )
  } else if (officeDetails !== null) {
    var URL = officeDetails.item
    var page_url = Office_Page_Url.alias.split("/")
    return (
      <>
        <OfficeDetails slug={URL} alias={page_url.pop()} />
      </>
    )
  } else if (researchDetails !== null) {
    var URL = researchDetails.item
    var page_url = Research_Page_Url.alias.split("/")
    return (
      <>
        <ResearchDetails slug={URL} alias={page_url.pop()} />
      </>
    )
  } else if (expressesDetails !== null) {
    var URL = expressesDetails.item
    var page_url = Expresses_Page_Url.alias.split("/")
    return (
      <>
        <ExpressesDetails slug={URL} alias={page_url.pop()} />
      </>
    )
  } else {
    return (
      <Layout Alias={Alias}>
        <SEO
          title={Page ? Page?.Meta_Title : ""}
          description={Page ? Page.Meta_Description : ""}
        />
        <Helmet
          bodyAttributes={{
            class: `templates-default-template alias-${
              Page ? Page?.Alias : ""
            } homepage ${Page ? Page?.Custom_CSS_Class : ""}`,
          }}
        />
        {Page?.Pagename === "Home" ?
          <Home 
          Modules={Modules}
            Page={Page}
            branchDetails={props.location?.state}
            />
        : Page?.Pagename === "Branch Finder" ?
        <Offices 
        Modules={Modules}
          Page={Page}
          branchDetails={props.location?.state}
          />
      : Page?.Pagename === "Find a DNG Team Member" ?
      <Team 
      Modules={Modules}
        Page={Page}
        branchDetails={props.location?.state}
        />
    : Page ? (
          <PageModules
            Modules={Modules}
            Page={Page}
            branchDetails={props.location?.state}

            // contact={contact}
          />
        ) : null}
      </Layout>
    )
  }
}

export default DefaultTemplate

export const pageQuery = graphql`
  query DeafultQuery($id: ID!) {
    glstrapi {
      page(id: $id, publicationState: LIVE) {
        id
        imagetransforms
        Banner_Content
        Alias
        Banner_Title
        Banner_Type
        Custom_CSS_Class
        Layout
        Meta_Description
        Meta_Title
        Video_url
        You_Tube_Thumbnail {
          url
        }
        Pagename
        Publish
        Show_Form
        Show_Review
        Cta_Links {
          CTA_Btn_Label1
          CTA_Btn_Label2
          HashLink1
          HashLink2
          CTA_Btn_Url1 {
            Alias
          }
          CTA_Btn_Url2 {
            Alias
          }
        }
        Banner_Image {
          alternativeText
          url
        }
        Modules {
          ... on GLSTRAPI_ComponentComponentsContentBlock {
            __typename
            id
            Content
          }
          ... on GLSTRAPI_ComponentComponentsGridBlock {
            __typename
            id
            Align_Type
            Content
            Image {
              alternativeText
              url
            }
            Menu_Title
            Sticky_Top_Menu
            Video_Url
          }
          ... on GLSTRAPI_ComponentComponentsExploreGridTails {
            __typename
            id
            Content
            Menu_Title
            Sticky_Top_Menu
            Add_Explore_Grid {
              Content
              Title
              Video_Url
              Image {
                alternativeText
                url
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsChooseCollections {
            __typename
            id
            Choose_Collection
            Content
            Title
            Menu_Title
            Sticky_Top_Menu
          }
          ... on GLSTRAPI_ComponentComponentsTextContent {
            __typename
            id
            TextContent
            Title
          }
          ... on GLSTRAPI_ComponentComponentsPeoplesBlock {
            id
            __typename
            Content
            Title
            Menu_Title
            Sticky_Top_Menu
            Image {
              alternativeText
              url
            }
            Video_Url
            People_List {
              team {
                URL
                Name
                Text
                Video_Url
                Image {
                  alternativeText
                  url
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsAboutBlock {
            __typename
            id
            Content
            Background_Color
            Title
            Video_Url
            Tail_Type
            Align_type
            Tails {
              Url {
                Alias
              }
              Title
              Label
              Content
            }
            choose_teams {
              Designation
              Name
              Phone
              Email
              Image {
                alternativeText
                url
              }
            }
            Image {
              alternativeText
              url
            }
          }
          ... on GLSTRAPI_ComponentComponentsExploreBlock {
            __typename
            id
            Title
            Explore_Tails {
              Title

              Image {
                url
                alternativeText
              }
              Description
              all_menus {
                URL
                Page {
                  Banner_Content
                  Pagename
                  Banner_Title
                  Banner_Image {
                    alternativeText
                    url
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsServicesCta {
            __typename
            id
            Content
            Title
            Tail_Block {
              Icon {
                url
                alternativeText
              }
              Parent_label
              Parent_Url {
                Alias
              }
              Sub_Links {
                Label
                Url {
                  Alias
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsTestimonials {
            __typename
            id
            Add_Testimonials {
              Content
              Name
              Text
              Image {
                alternativeText
                url
              }
            }
            Title
          }
          ... on GLSTRAPI_ComponentComponentsVideoTestimonials {
            __typename
            id
            Testimonials_Video_Franchise {
              Content
              Video_Url
              Company_Name
              Secondary_Small_Image {
                alternativeText
                url
              }
              Text
              image {
                alternativeText
                url
              }
            }
            Title
          }
          ... on GLSTRAPI_ComponentComponentsGetStarted {
            __typename
            id
            Title
            Content
            Design_Title
            Get_Started_Cta_Links {
              CTA_Label
              Link_Type
              CTA_Url {
                Alias
              }
              Model_View
            }
          }
        }
        Cta_Links {
          CTA_Btn_Label1
          CTA_Btn_Label2
          HashLink1
          HashLink2
          CTA_Btn_Url1 {
            Alias
          }
          CTA_Btn_Url2 {
            Alias
          }
        }
      }
    }
  }
`
