import React,{useState} from 'react';
import { Link } from 'gatsby';
import Accordion from "react-bootstrap/Accordion"
import './ContentBlock.scss';
import ReactMarkdown from "react-markdown"
import { inViewOptions, contentItemStagger, contentItem, fadeInFromLeft, fadeInFromRight } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
const OfficeProperty = (props) => {
	// const [activeId, setActiveId] = useState("")
	// function toggleActive(id) {
	//   if (activeId === id) {
	// 	setActiveId(null)
	//   } else {
	// 	setActiveId(id)
	//   }
	// }
	return (
		<React.Fragment>
		<motion.div className="ContentBlock" variants={fadeInFromLeft}>
		{props?.Content && <ReactMarkdown source={props?.Content} allowDangerousHtml />}
			{/* <div className="accordion-wrap">
			<Accordion defaultActiveKey={activeId}>
				<ul>
					<li>
						
						<Accordion.Toggle
                    onClick={() => toggleActive("0")}
					className={
                        activeId === "0"
                          ? "accordion-header minus"
                          : "accordion-header"
                      }
                    eventKey="0"
					>
						Areas we cover
							<i className="icon-plus"></i>
							<i className="icon-minus"></i>
					
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="0">
						<div className="accordion-content">
							<span className="accordion-info-text">BER: D2</span>
							<span className="accordion-info-text">BER No: 100408152</span>
							<span className="accordion-info-text">Performance Indicator: 296.21 kWh/m?/yr</span>
						</div>
						</Accordion.Collapse>
					</li>
					<li>
						
						<Accordion.Toggle
                    onClick={() => toggleActive("1")}
					className={
                        activeId === "1"
                          ? "accordion-header minus"
                          : "accordion-header"
                      }
                    eventKey="1"
					>
						Buying or Selling
							<i className="icon-plus"></i>
							<i className="icon-minus"></i>
					
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="1">
						<div className="accordion-content">
							<span className="accordion-info-text">BER: D2</span>
							<span className="accordion-info-text">BER No: 100408152</span>
							<span className="accordion-info-text">Performance Indicator: 296.21 kWh/m?/yr</span>
						</div>
						</Accordion.Collapse>
					</li>
					<li>
					
						<Accordion.Toggle
                    onClick={() => toggleActive("2")}
					className={
                        activeId === "2"
                          ? "accordion-header minus"
                          : "accordion-header"
                      }
                    eventKey="2"
					>
						Letting or Renting
							<i className="icon-plus"></i>
							<i className="icon-minus"></i>
					
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="2">
						<div className="accordion-content">
							<span className="property-info-text">BER: D2</span>
							<span className="property-info-text">BER No: 100408152</span>
							<span className="property-info-text">Performance Indicator: 296.21 kWh/m?/yr</span>
						</div>
						</Accordion.Collapse>
					</li>
					<li>
						
						<Accordion.Toggle
                    onClick={() => toggleActive("3")}
					className={
                        activeId === "3"
                          ? "accordion-header minus"
                          : "accordion-header"
                      }
                    eventKey="3"
					>
						About the Local Area
							<i className="icon-plus"></i>
							<i className="icon-minus"></i>
					
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="3">
						<div className="accordion-content">
							<span className="property-info-text">BER: D2</span>
							<span className="property-info-text">BER No: 100408152</span>
							<span className="property-info-text">Performance Indicator: 296.21 kWh/m?/yr</span>
						</div>
						</Accordion.Collapse>
					</li>
				</ul>
				</Accordion>
				</div> */}
		</motion.div>


</React.Fragment>
	)
}

export default OfficeProperty
