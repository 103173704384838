/**
 * Rental Yield Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Button, Container } from "react-bootstrap"
import { filterNumber, numberFormat, rentalYield,numberFormat1 } from "./util"
import { defaultValues } from "./config"
import ReactMarkdown from "react-markdown"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
// use project specific import here

// DO NOT DO ANY CHNAGES - START
const RentalyieldCalc = props => {
  
  
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState("")
  const [currentPrice, setCurrentPrice]=useState("")
  const [rent, setRent] = useState("")
  const [selectPType, setSelectPtype]=useState("")

  const [showfirstBlock, setShowFirstBlock]=useState(false)
  const [showSecBlock, setShowSecBlock]=useState(false)

  const [option, setOption]=useState(true);

  const [stampDuty, setStampDuty]=useState("")
  const [survey, setSurvey]=useState("")
  const [legal, setLegal]=useState("")
  const [mortgage, setMortgage]=useState("")
  const [refurbishment, setRefurbishment]=useState("")
  const [otherBuying, setOtherBuying]=useState("")
  const [yearlyCost, setYearlyCost]=useState("")
  const [mortgagePayment, setMortgagePayment]=useState("")
  const [repair, setRepair]=useState("")
  const [groundRent, setGroundRent]=useState("")
  const [insurance, setInsurance]=useState("")
  const [lettingAgent, setLettingAgent]=useState("")
  const [landlordUtil, setLandlordUtil]=useState("")
  const [councilTax, setCouncilTax]=useState("")
  const [vacancy, setVacancy]=useState("")
  const [otherProperty, setOtherProperty]=useState("")

  const [grossRental, setGrossRental]=useState("")
  const [netRental, setNetRental]=useState("")

  const [selected, setSelected]=useState(true)


  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(numberFormat1(val))
  }

  const handleCurrentPrice = event => {
    let currentVal = filterNumber(event.target.value)
    setCurrentPrice(numberFormat1(currentVal))
  }

  const handleRent = event => {
    let d = filterNumber(event.target.value)
    setRent(numberFormat1(d))
    const yearlyCost=d*12
    setYearlyCost(numberFormat1(yearlyCost))

  }
  const handleStampDuty=(event)=>{
    let stamp=filterNumber(event.target.value)
    if(isNaN(stamp)){
      setStampDuty("")
      }
      else{
        setStampDuty(numberFormat1(stamp))
      }
  }
  const handleSurvey=(event)=>{
    let surveyFees=filterNumber(event.target.value)
    if(isNaN(surveyFees)){
    setSurvey("")
    }
    else{
      setSurvey(numberFormat1(surveyFees))
    }
  }

  const handleLegal=(event)=>{
    let legalFees=filterNumber(event.target.value)
    if(isNaN(legalFees)){
      setLegal("")
      }
      else{
        setLegal(numberFormat1(legalFees))
      }
  }

  const handleMortgage=(event)=>{
    let mortgageFees=filterNumber(event.target.value)
    if(isNaN(mortgageFees)){
      setMortgage("")
      }
      else{
        setMortgage(numberFormat1(mortgageFees))
      }
  }

  const handleRefurbish=(event)=>{
    let refurbish=filterNumber(event.target.value)
    if(isNaN(refurbish)){
      setRefurbishment("")
      }
    else{
      setRefurbishment(numberFormat1(refurbish))
        
      }
  }

  const handleOtherBuying=(event)=>{
    let otherbuy=filterNumber(event.target.value)
    if(isNaN(otherbuy)){
      setOtherBuying("")
      }
    else{
      setOtherBuying(numberFormat1(otherbuy))
      }
  }

  const handleYearCost=(event)=>{
    let yearCost=filterNumber(event.target.value)
    setYearlyCost(numberFormat1(yearCost))
  }

  const handleMortgagePayment=(event)=>{
    let payment=filterNumber(event.target.value)
    if(isNaN(payment)){
      setMortgagePayment("")
      }
    else{
      setMortgagePayment(numberFormat1(payment))
      }    
  }

  const handleRepair=(event)=>{
    let rep=filterNumber(event.target.value)
    if(isNaN(rep)){
      setRepair("")
      }
    else{
      setRepair(numberFormat1(rep))
      }
  }

  const handleGroundRent=(event)=>{
    let ground=filterNumber(event.target.value)
    if(isNaN(ground)){
      setGroundRent("")
      }
    else{
      setGroundRent(numberFormat1(ground))
      }
  }

  const handleInsurance=(event)=>{
    let insure=filterNumber(event.target.value)
    if(isNaN(insure)){
      setInsurance("")
      }
    else{
      setInsurance(numberFormat1(insure))
      }
  }

  const handleLettingAgent=(event)=>{
    let letting=filterNumber(event.target.value)
    if(isNaN(letting)){
      setLettingAgent("")
      }
    else{
      setLettingAgent(numberFormat1(letting))
      }
  }
  const handleLandlordUtility=(event)=>{
    let land=filterNumber(event.target.value)
    if(isNaN(land)){
      setLandlordUtil("")
      }
    else{
      setLandlordUtil(numberFormat1(land))
      }
  }

  const handleCouncilTax=(event)=>{
    let council=filterNumber(event.target.value)
    if(isNaN(council)){
      setCouncilTax("")
      }
    else{
      setCouncilTax(numberFormat1(council))
      }
  }

  const handleVacancy=(event)=>{
    let vacant=filterNumber(event.target.value)
    if(isNaN(vacant)){
      setVacancy("")
      }
    else{
      setVacancy(numberFormat1(vacant))
      }
  }

  const handleOtherProperty=(event)=>{
    let otherProp=filterNumber(event.target.value)
    if(isNaN(otherProp)){
      setOtherProperty("")
      }
    else{
      setOtherProperty(numberFormat1(otherProp))
      }
  }


  const getResult = () => {
    if(option){
    const otherPurchaseCost=filterNumber(stampDuty===""?0:stampDuty)+filterNumber(survey===""?0:survey)+
    filterNumber(legal===""?0:legal) +filterNumber(mortgage===""?0:mortgage)+filterNumber(refurbishment===""?0:refurbishment)+
    filterNumber(otherBuying===""?0:otherBuying);
   
    
    const annualCost=filterNumber(mortgagePayment===""?0:mortgagePayment)+filterNumber(repair===""?0:repair)+
    filterNumber(groundRent===""?0:groundRent)+filterNumber(insurance===""?0:insurance)+filterNumber(lettingAgent===""?0:lettingAgent)+
    filterNumber(landlordUtil===""?0:landlordUtil)+filterNumber(councilTax===""?0:councilTax)+filterNumber(vacancy===""?0:vacancy)+
    filterNumber(otherProperty===""?0:otherProperty);
    const purchaseCost=filterNumber(purchasePrice) + parseInt(otherPurchaseCost);
    let gross_rental_yield= filterNumber(yearlyCost) / purchaseCost * 100;
    let net_rental_yield   = (filterNumber(yearlyCost) - annualCost)/purchaseCost * 100;
    setGrossRental(gross_rental_yield)
    setNetRental(net_rental_yield)
    }
    else{
      let annualcost= filterNumber(mortgagePayment===""?0:mortgagePayment)+filterNumber(repair===""?0:repair)+
      filterNumber(groundRent===""?0:groundRent)+filterNumber(insurance===""?0:insurance)+filterNumber(lettingAgent===""?0:lettingAgent)+
      filterNumber(landlordUtil===""?0:landlordUtil)+filterNumber(councilTax===""?0:councilTax)+filterNumber(vacancy===""?0:vacancy)+
      filterNumber(otherProperty===""?0:otherProperty);

      let gross_annual=filterNumber(yearlyCost) / filterNumber(currentPrice) * 100;
      setGrossRental(gross_annual)
      let net_rental = (filterNumber(yearlyCost) - annualcost)/filterNumber(currentPrice) * 100;
      setNetRental(net_rental)
    }
    
  }

  const handleSubmit = event => {
    
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } 
    // else if(filterNumber(purchasePrice)===0||filterNumber(rent)===0||filterNumber(currentPrice)===0){
    //   setResult(false)
    //   setShowerror(true)
    // }
    else {
      if (filterNumber(purchasePrice) && filterNumber(rent) ) {
        getResult()
        setShowerror(false)
      }
      if(filterNumber(currentPrice) && filterNumber(rent)){
        getResult()
        setShowerror(false)
      }
    }
  }

  // useEffect(() => {
  //   if (instantResult) {
  //     getResult(purchasePrice, rent)
  //   }
  // }, [instantResult, purchasePrice, rent])

  // DO NOT DO ANY CHNAGES - END
  const purchaseType=[
    {value:"The purchase price of the property"},
    {value:"The current value of the property"}
  ]

  const handlechange=(e)=>{
    setSelectPtype(e.target.value)
      setOption(!option)
    
  }

  const showMoreFeilds=()=>{
    setShowFirstBlock(!showfirstBlock)
    const getId=document.getElementById("caret");
    getId.classList.toggle("caret-up-arrow")
  }

  const viewMoreFeilds=()=>{
    setShowSecBlock(!showSecBlock)
    const element=document.getElementById("caret1");
    element.classList.toggle("caret1-up-arrow")
  }

  const handlePlus=()=>{
    setSelected(!selected)
  } 


  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="about"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
          style={{
            backgroundColor:
              props.Background_Color === "White"
                ? "#ffffff"
                : props.Background_Color === "Grey"
                ? "#F7F8FA"
                : "#ffffff",
          }}
        >

    <Container>
    <div className="form mortgate-form rental-yeild-calc">
      {showerror && (
        <div className="alert-error">
          <p>Please provide highlighted fields </p>
        </div>
      )}

        {props?.Title && (
          <div className="cal-header">
            <motion.div variants={fadeInFromLeft} className="yield-title">
              <ReactMarkdown source={props?.Title} allowDangerousHtml />
            </motion.div>

            {/* <div className="icon-con" onClick={()=>handlePlus()} >{selected?<i className='icon-minus'></i>:<i className="icon-plus"></i>}</div> */}
            </div>
          )}
       {/* {selected&&    */}
      <Form
        name="RentalyieldCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="rental-yeild-form"
      >
        <input type="hidden" name="bot-field" />
        {/* <p>Find out how much you will have to pay by using our Rental Yield Calculator.</p> */}
        <Form.Group controlId="calculate-yeild">
          <Form.Label>Calculate yield for:</Form.Label>
          <Form.Control
            className="form-control"
            required={false}
            name="purchase_type"
            // placeholderText={placeholder}
            onChange={(e)=>handlechange(e)}
            // onFocus={focusEvent}
            as="select"
            value={selectPType}
          >
            {purchaseType.map(type=>(
              <option value={type.value}>{type.value}</option>
            ))}
          </Form.Control>
        </Form.Group>
        {option?
        <Form.Group controlId="purchase_price">
          <Form.Label>Purchase Price:</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="purchase_price"
            value={purchasePrice? `€ ${purchasePrice}`:""}
            placeholder=""
            onChange={handlePrice}
          />
        </Form.Group>
        :
        <Form.Group controlId="current_price">
        <Form.Label>Current Property Value:</Form.Label>
        <Form.Control
          className="form-control"
          required
          type="text"
          name="current_price"
          value={currentPrice? `€ ${currentPrice}`:""}
          placeholder=""
          onChange={handleCurrentPrice}
        />
      </Form.Group>
        }

         {option&&     
        <div className="other-purchase-container">
          <div className="other-purchase">
            
            <p>Other Purchase Costs <span onClick={()=>showMoreFeilds()} className="caret-down-icon" id="caret"></span></p>
          {showfirstBlock&&
           <div className="purchase-form-feilds">
            <Form.Group controlId="stamp">
            <Form.Label>Stamp Duty:</Form.Label>
            <Form.Control
            className="form-control stamp-duty"
            type="text"
            name="stamp_duty"
            value={stampDuty?`€ ${stampDuty}`:""}
            placeholder=""
            onChange={handleStampDuty}
          />
            </Form.Group>
            
            <Form.Group controlId="survey_fees">
            <Form.Label>Valuation/Survey Fees:</Form.Label>
            <Form.Control
            className="form-control survey-fees"
            type="text"
            name="survey_fees"
            value={survey?`€ ${survey}`:""}
            placeholder=""
            onChange={handleSurvey}
          />
            </Form.Group>
            <Form.Group controlId="legal_fees">
            <Form.Label>Legal Fees:</Form.Label>
            <Form.Control
            className="form-control legal-fees"
            type="text"
            name="legal_fees"
            value={legal?`€ ${legal}`:""}
            placeholder=""
            onChange={handleLegal}
          />
            </Form.Group>
            <Form.Group controlId="mortgage_fees">
            <Form.Label>Mortgage Fees:</Form.Label>
            <Form.Control
            className="form-control mortgage-fees"
            type="text"
            name="mortgage_fees"
            value={mortgage?`€ ${mortgage}`:""}
            placeholder=""
            onChange={handleMortgage}
          />
            </Form.Group>
            <Form.Group controlId="refurbishment">
            <Form.Label>Refurbishment Costs:</Form.Label>
            <Form.Control
            className="form-control refurbishment"
            type="text"
            name="refurbishment"
            value={refurbishment?`€ ${refurbishment}`:""}
            placeholder=""
            onChange={handleRefurbish}
          />
            </Form.Group>
            <Form.Group controlId="other-buying">
            <Form.Label>Other Buying Costs:</Form.Label>
            <Form.Control
            className="form-control other-buying"
            type="text"
            name="other-buying"
            value={otherBuying?`€ ${otherBuying}`:""}
            placeholder=""
            onChange={handleOtherBuying}
          />
            </Form.Group>
           
        </div>
          }
        </div>
        </div>
        }

        <Form.Group controlId="rent">
          <Form.Label>Monthly Rent:</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="monthly_rent"
            value={rent?`€ ${rent}`:""}
            placeholder=""
            onChange={handleRent}
          />
        </Form.Group>

        <Form.Group controlId="yearly_cost">
          <Form.Label>Annual Rent:</Form.Label>
          <Form.Control
            className="form-control yearly_cost"
            required
            type="text"
            name="yearly_cost"
            value={yearlyCost?`€ ${yearlyCost}`:""}
            placeholder=""
            onChange={handleYearCost}
          />
        </Form.Group>

        <div className="other-cost-component">
          <div className="other-cost-container">
            <p>Other Annual Costs (optional - but required for net yield calculations) <span onClick={()=>viewMoreFeilds()} className="caret-down-icon" id="caret1"></span></p>
            {showSecBlock&&
              <div className="other-cost-form">
              <Form.Group controlId="mortgage_payment">
              <Form.Label>Mortgage Payment:</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="mortgage_payment"
                    value={mortgagePayment?`€ ${mortgagePayment}`:""}
                    placeholder=""
                    onChange={handleMortgagePayment}
                  />
              </Form.Group>
              <Form.Group controlId="repairs">
              <Form.Label>Repairs and Maintenance:</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="repairs"
                    value={repair?`€ ${repair}`:""}
                    placeholder=""
                    onChange={handleRepair}
                  />
              </Form.Group>
              <Form.Group controlId="ground_rent">
              <Form.Label>Ground Rent & Service:</Form.Label>
                  <Form.Control
                    className="form-control ground_rent"
                    type="text"
                    name="ground_rent"
                    value={groundRent?`€ ${groundRent}`:""}
                    placeholder=""
                    onChange={handleGroundRent}
                  />
              </Form.Group>
              <Form.Group controlId="insurance">
              <Form.Label>Insurance:</Form.Label>
                  <Form.Control
                    className="form-control insurance"
                    type="text"
                    name="insurance"
                    value={insurance?`€ ${insurance}`:""}
                    placeholder=""
                    onChange={handleInsurance}
                  />
              </Form.Group>
              <Form.Group controlId="letting_agent">
              <Form.Label>Letting Agent Fees:</Form.Label>
                  <Form.Control
                    className="form-control insurance"
                    type="text"
                    name="letting_agent"
                    value={lettingAgent?`€ ${lettingAgent}`:""}
                    placeholder=""
                    onChange={handleLettingAgent}
                  />
              </Form.Group>
              <Form.Group controlId="landlord_utility">
              <Form.Label>Landlord Utility Bills:</Form.Label>
                  <Form.Control
                    className="form-control landlord_utility"
                    type="text"
                    name="landlord_utility"
                    value={landlordUtil?`€ ${landlordUtil}`:""}
                    placeholder=""
                    onChange={handleLandlordUtility}
                  />
              </Form.Group>
              <Form.Group controlId="council_taxs">
              <Form.Label>Council Tax:</Form.Label>
                  <Form.Control
                    className="form-control council_taxs"
                    type="text"
                    name="council_taxs"
                    value={councilTax?`€ ${councilTax}`:""}
                    placeholder=""
                    onChange={handleCouncilTax}
                  />
              </Form.Group>
              <Form.Group controlId="vacancy_loss">
              <Form.Label>Vacancy Loss:</Form.Label>
                  <Form.Control
                    className="form-control vacancy_loss"
                    type="text"
                    name="vacancy_loss"
                    value={vacancy?`€ ${vacancy}`:""}
                    placeholder=""
                    onChange={handleVacancy}
                  />
              </Form.Group>
              <Form.Group controlId="other_prop">
              <Form.Label>Other Property Costs:</Form.Label>
                  <Form.Control
                    className="form-control vacancy_loss"
                    type="text"
                    name="other_prop"
                    value={otherProperty?`€ ${otherProperty}`:""}
                    placeholder=""
                    onChange={handleOtherProperty}
                  />
              </Form.Group>
              </div>
            }
          </div>
        </div>
        
        <Button type="submit" className="btn btn-pink">
          Calculate
        </Button>
      </Form>
      {/* } */}

      {selected&&
      <div>
      {grossRental&&(
        <div className="gross-value">
          <p>Gross Rental Yield : {grossRental.toFixed(2)}%</p>
        </div>
      )}
      {netRental&&
        <div>
            <p>Net Rental Yield :    {netRental.toFixed(2)}%</p>
        </div>
      }
      </div>
      }
    </div>
    </Container>
    </motion.section>
      )}
    </InView>
  )
}

RentalyieldCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  monthlyRent: defaultValues.MONTHLY_RENT,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  instantResult: defaultValues.INSTANT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
}

RentalyieldCalc.propTypes = {
  propertyPrice: PropTypes.any,
  monthlyRent: PropTypes.any,
  currency: PropTypes.string,
  instantResult: PropTypes.any,
  pricePrefix: PropTypes.any,
}

export default RentalyieldCalc