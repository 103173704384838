import React, { useState, useEffect } from "react"
import { Container, Row, Col, NavItem, Modal } from "react-bootstrap"
import { Link } from "gatsby"
//import ScrollAnimation from "react-animate-on-scroll"
import Slider from "react-slick"
import axios from "axios"
import "./trendingProperties.scss"
import SimilarPropertyImg from "../../../images/similar-property-img.png"
import propertyLabel from "../../../images/property-label.png"
import NoImage from "../../../images/no-image.png"
import PlayVideo from "@Components/PlayVideo/PlayVideo"
import getVideoId from "get-video-id"
import { ShowProcessedImage } from "@Components/common/ggfx-client/module/components/show-image"
import imageConfig from "../../../../static/images/config.json"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import SearchResultsImagePopup from "@Components/SearchResults/SearchResultsImagePopup2"

import Lightbox from "react-image-lightbox"
import Content from "../../Content/Content"
const TrendingProperties = props => {
  const [isPlay, setIsPlay] = useState(false)

  const [videoid, setVideoId] = useState("")
  const [video_type, setVideoType] = useState("")

  const playVideoHandler = (video_url, video_type) => {
    setIsPlay(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }

  const [show, setShow] = useState(false)
  const [fullscreen, setFullscreen] = useState(true)

  function handleShow(video_url, video_type) {
    setShow(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }
  // Property images lightbox

  // Property details images lightbox
  var propertyImagePopup = []
  const propertyImages = props?.propertyData?.images
  var propertyLightImages =
    propertyImages &&
    propertyImages.map(img => propertyImagePopup.push(img.url))
  // Property details images lightbox
  //
  const [propItems, setPropItems] = useState([])

  const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || ""

  const getitems = async url => {
    try {
      const { data } = await axios.post(
        url,
        { branch_id: props.branchId, limit: props?.limit || 3 },
        {
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          },
        }
      )
      setPropItems(data.data)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    let url =
      process.env.GATSBY_STRAPI_SRC +
      "/stb-trending-properties/getTrendingProperties"
    getitems(url)
  }, [])

  // Slider settings
  var settings = {
    dots: false,
    nav: true,
    infinite: propItems.length > 3 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  // Slider settings
  const findStatus=propItems?.filter(data=>data.status==="For Sale"||data.status==="To Let");

  return (
    findStatus.length > 0 && (
      <React.Fragment>
        {/* <ScrollAnimation animateIn="fadeInUp" animateOnce={true}> */}
          <div className="relatednews-block opinion">
            <Container>
              <Row>
                <Col lg={12}>
                  <h2>Trending Properties For Sale/Rent that may Interest you...</h2>
                </Col>
              </Row>
             
              <Slider className="opinion-list grid overright" {...settings}>
                {findStatus.map((hit, index) => {
                  // Property detail url structure
                  let uriStr = ""


                  if (
                    hit.search_type === "sales" &&
                    hit.department === "residential"
                  ) {
                    uriStr = `property-for-sale/`
                  } else if (
                    hit.search_type === "lettings" &&
                    hit.department === "residential"
                  ) {
                    uriStr = `property-to-rent/`
                  } else if (
                    hit.search_type === "sales" &&
                    hit.department === "commercial"
                  ) {
                    uriStr = `commercial-property-for-sale/`
                  } else if (
                    hit.search_type === "lettings" &&
                    hit.department === "commercial"
                  ) {
                    uriStr = `commercial-property-to-rent/`
                  }
                  else if(
                    hit.search_type === "sales" &&
                    hit.department === "NH"
                  ){
                    uriStr = `property-for-sale/`
                  }
                  // Property detail url structure
                  let videotourid =
                    hit?.video_tour?.url && getVideoId(hit?.video_tour?.url)

                  let virtualid =
                    hit?.virtual_url?.url && getVideoId(hit?.virtual_url?.url)
                  // ggfx images
                  let processedImages = JSON.stringify({})
                  if (hit?.imagetransforms?.images_Transforms) {
                    processedImages = hit.imagetransforms.images_Transforms
                  }

                  return (
                    <div className="property-card">
                      <div className="property-card-wrapper">
                        <div className="property-img">
                          {
                            <Link to={`/${uriStr}${hit.slug}-${hit.id}/`}>
                              {/* {JSON.stringify(hit?.images[0])} */}
                              {/* <ShowProcessedImage images={hit.images[0]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults.sizes} /> */}
                              {hit?.images[0]?.url ? (
                                <ImageTransform
                                  imagesources={hit?.images[0]?.url}
                                  renderer="srcSet"
                                  imagename="property.images.detail"
                                  attr={{
                                    alt:
                                      hit?.display_address +
                                      " - DNG Estate Agents",
                                  }}
                                  imagetransformresult={processedImages}
                                  id={hit.id}
                                  testparam={true}
                                />
                              ) : (
                                <img src={NoImage} alt="property" />
                              )}
                            </Link>
                          }
                          <div className="property-wrapper">
                            <div className="left-block">
                              {hit?.images && hit?.images?.length > 0 && (
                                <SearchResultsImagePopup
                                  propertyId={hit._id}
                                  propertyImage={hit?.images}
                                  imgLength={3}
                                />
                              )}

                              {/* {
                                                                hit?.images &&
                                                                hit?.images?.length > 0 &&
                                                                <div className="image-wrap" onClick={(e) => openPropertyImage(e, 0)}>
                                                                <i className="icon-camera"></i>
                                                                <div className="image-count">1/{hit?.images?.length}</div>
                                                            </div>
                                                            } */}
                              {/* Banner image popup */}
                              {/* {isOpen && (
                                                                <Lightbox
                                                                    mainSrc={propertyLightImages[photoIndex]}
                                                                    nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
                                                                    prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
                                                                    onCloseRequest={() => setIsOpen(false)}
                                                                    onMovePrevRequest={() =>
                                                                        setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
                                                                    }
                                                                    onMoveNextRequest={() =>
                                                                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                                                                    }
                                                                />
                                                            )} */}
                              {/* Banner image popup */}
                              <div className="property-view">
                                {hit.video_tour?.url &&
                                  (videotourid?.service === "youtube" ? (
                                    <div className="video-wrap">
                                      <button
                                        onClick={() =>
                                          playVideoHandler(
                                            hit.video_tour?.url,
                                            "youtube"
                                          )
                                        }
                                      >
                                        <i className="icon-play"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="video-wrap">
                                      <button
                                        onClick={() =>
                                          handleShow(
                                            hit.video_tour?.url,
                                            "custom_video"
                                          )
                                        }
                                      >
                                        <i className="icon-play"></i>
                                      </button>
                                    </div>
                                  ))}
                                {hit.virtual_url?.url &&
                                  (virtualid?.service === "youtube" ? (
                                    <div className="video-wrap">
                                      <button
                                        onClick={() =>
                                          playVideoHandler(
                                            hit.virtual_url?.url,
                                            "youtube"
                                          )
                                        }
                                      >
                                        <i className="icon-play"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="video-wrap">
                                      <button
                                        onClick={() =>
                                          handleShow(
                                            hit.virtual_url?.url,
                                            "custom_video"
                                          )
                                        }
                                      >
                                        <i className="icon-play"></i>
                                      </button>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="right-block sb-myacc">
                              {/* <button>
                                      <i className={'icon-heart' } />
                                      <span className="span-btn-text d-none d-md-inline-block">{'save' }</span>
                                  </button> */}
                              {hit.department === "residential" &&
                              <Link to={myaccountURL}>
                              <button type="button">
                                <i className="icon-heart" />
                                <span className="btn-text d-none d-md-inline-block"></span>
                              </button>
                              </Link>
                              }
                            </div>
                          </div>
                          {/* {hit.status && (
                            <div className="property-tag">{hit.status}</div>
                          )} */}
                        </div>
                        <div className="property-details">
                          <div className="property-name">
                            <Link to={`/${uriStr}${hit.slug}-${hit.id}/`}>
                              {hit.display_address && hit.display_address}
                              {/* <br />{hit.title && hit.title} */}
                            </Link>
                          </div>
                          <p className="property-desc-content">
                          {hit.description &&                        
                         <Content
                         Content={hit.description}
                         page="search-result"
                         uriStr={uriStr}
                         hit={hit}
                         />
                        }
                          </p>
                          <div className="property-price">
                          {hit?.price_qualifier === "POA"
                          ? "€ Price on Application"
                          : hit.price > 10000&&hit?.search_type !== "lettings"
                          ?
                         `€${parseFloat(hit.price).toLocaleString()}`
                          : 
                          hit?.search_type === "lettings" &&hit.price>100?
                          `€${parseFloat(hit.price).toLocaleString()} ${                            
                            hit?.price_qualifier
                              ? hit?.price_qualifier==="Per Month"?"pcm":hit?.price_qualifier==="Per Week"?"pw":hit?.price_qualifier
                              : ""
                          }`
                          : "€ Price on Application"}


                            {/* €{hit.price && hit.price.toLocaleString()}{" "}
                            {(hit.searchType === "lettings" &&
                              hit.department === "commercial") ||
                            (hit.searchType === "lettings" &&
                              hit.department === "residential")
                              ? hit?.price_qualifier
                              : ""} */}
                          </div>
                          <div className="bottom-block">
                            {hit.bedroom >= 1 && (
                              <div className="bedroom-count">
                                <i className="icon-bed"></i>
                                {hit.bedroom}
                              </div>
                            )}
                            {hit.bathroom >= 1 && (
                              <div className="bathroom-count">
                                <i className="icon-tub"></i>
                                {hit.bathroom}
                              </div>
                            )}
                            {hit.reception >= 1 && (
                              <div className="lounge-count d-none d-xl-flex">
                                <i className="icon-couch"></i>
                                {hit.reception}
                              </div>
                            )}
                            {hit.floorarea_min>=1 && (
                              <div className="property-area">
                                <i className="icon-cube"></i>
                                {(hit.floorarea_min / 10.7639)
                                  .toFixed(1)
                                  .replace(".0", "")}{" "}
                                {"m"}
                                <sup>2</sup>
                              </div>
                            )}
                            {hit.extrasField && hit.extrasField.pBERRating && (
                              <div className="property-label">
                                <div className="d-flex justify-content-start align-items-center property-ber-rating">
                                  <div className="ber-black">BER</div>
                                  <div
                                    className={
                                      "ber-green " +
                                      hit.extrasField.pBERRating.toLowerCase()
                                    }
                                  >
                                    {hit.extrasField.pBERRating}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

                {/* <div className='property-card'>
                                <div className="property-card-wrapper">
                                    <div className="property-img">
                                        <Link to={`/`}>
                                          <img src={SimilarPropertyImg} alt="property" />
                                          <div className="property-wrapper">
                                              <div className="left-block">
                                                  <div className="image-wrap">
                                                      <i className="icon-camera"></i>
                                                        <div className="image-count">1/{"2"}</div>
                                                  </div>
                                                  <div className="property-view">
                                                      <div className="video-wrap">
                                                          <button>
                                                              <i className="icon-play"></i>
                                                          </button>
                                                      </div>
                                                      <div className="floor-plan">
                                                          <button>
                                                              <i className="icon-floor-plan"></i>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="right-block">
                                                  <button>
                                                      <i className={'icon-heart' } />
                                                      <span className="span-btn-text d-none d-md-inline-block">{'save' }</span>
                                                  </button>
                                              </div>
                                          </div>
                                          <div className="property-tag">{"For sale"}</div>
                                        </Link>
                                    </div>
                                    <div className="property-details">
                                        <div className="property-name">
                                            <Link to={`/`}>
                                                {"test address"}
                                            </Link>
                                        </div>
                                        <p>{"test title"}</p>
                                        <div className="property-price">€{"124562"}</div>
                                        <div className="bottom-block">
                                            <div className="bedroom-count">
                                                <i className="icon-bed"></i>{"2"}
                                            </div>
                                            <div className="bathroom-count">
                                                <i className="icon-tub"></i>{"1"}
                                            </div>
                                            <div className="lounge-count d-none d-xl-flex">
                                                <i className="icon-couch"></i>{"1"}
                                            </div>
                                            <div className="property-area">
                                                <i className="icon-cube"></i>{`${'132'}m`}<sup>2</sup>
                                            </div>
                                            <div className="property-label">
                                                <img src={propertyLabel} alt="label" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              </Slider>

            </Container>
          </div>
          {video_type === "youtube" ? (
            <PlayVideo
              isOpen={isPlay}
              videoId={videoid}
              stopPlay={() => {
                setIsPlay(false)
                setVideoType("")
              }}
            />
          ) : (
            <Modal
              show={show}
              fullscreen={fullscreen}
              onHide={() => {
                setShow(false)
                setVideoType("")
              }}
              dialogClassName="modal-fullscreen property-details-modal"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="p-0">
                <iframe src={videoid}></iframe>
              </Modal.Body>
            </Modal>
          )}
        {/* </ScrollAnimation> */}
      </React.Fragment>
    )
  )
}

export default TrendingProperties
