import React, { useState, useEffect } from "react"
import { Container, Row, Col, NavItem, Tabs, Tab } from "react-bootstrap"
import { Link } from "gatsby"
//import ScrollAnimation from "react-animate-on-scroll"
import Slider from "react-slick"
import axios from "axios"
import "./FeatureNewHomeProperties.scss"
import SimilarPropertyImg from "../../../images/similar-property-img.png"
import propertyLabel from "../../../images/property-label.png"
import NoImage from "../../../images/no-image.png"
import ReactMarkdown from "react-markdown"
const FeatureNewHomeProperties = props => {
  const [salespropItems, salessetPropItems] = useState([])
  // const [rentpropItems, rentsetPropItems] = useState([])
  const getitemssales = async url => {
    try {
      const { data } = await axios.get(url,
        {
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          },
        }
        )
      salessetPropItems(data)
      console.log(data)
    } catch (error) {}
  }
  // const getitemsrent = async url => {
  //     try {
  //         const { data } = await axios.get(url, {});
  //         rentsetPropItems(data)
  //     } catch (error) {

  //     }
  // }

  useEffect(() => {
    //
    let area = props.slug ? props.slug : ""
    let saleurl =
      process.env.GATSBY_STRAPI_SRC +
      "/new-developments?search_type=sales&_limit=6&_sort=price:DESC&publish=true&featured_new_developments=true" // TODO: base URL supposed to be from .env
    // let renturl = process.env.GATSBY_STRAPI_SRC + "/new-developments?search_type=lettings&_limit=6&_sort=price:DESC&publish=true"; // TODO: base URL supposed to be from .env

    getitemssales(saleurl)
    // getitemsrent(renturl)
  }, [])
  //

  // Slider settings
  var settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  // Slider settings

  return salespropItems && salespropItems.length > 0 ? (
    <React.Fragment>
      <div className="relatednews-block opinion feature-properties">
        <Container>
          <Row>
            <Col lg={12}>
              <ReactMarkdown source={props.Title} allowDangerousHtml />
            </Col>
          </Row>

          <Slider className="opinion-list grid overright" {...settings}>
            {salespropItems && salespropItems.length > 0
              ? salespropItems.map((item, index) => {
                  // Property detail url structure
                  // let uriStr = "";

                  // if (item.search_type === "sales" && item.department === 'residential') {
                  //     uriStr = `property-for-sale/`
                  // } else if (item.search_type === "lettings" && item.department === 'residential') {
                  //     uriStr = `property-to-rent/`
                  // } else if (item.search_type === "sales" && item.department === 'commercial') {
                  //     uriStr = `commercial-property-for-sale/`
                  // } else if (item.search_type === "lettings" && item.department === 'commercial') {
                  //     uriStr = `commercial-property-to-rent/`
                  // }
                  // Property detail url structure

                  return (
                    <div className="property-card">
                      <div className="property-card-wrapper">
                        <div className="property-img">
                          <Link
                            to={`/${"new-home-for-sale/"}${item?.slug}-${
                              item?.id
                            }/`}
                          >
                            <img
                              src={
                                item?.images[0].url
                                  ? item?.images[0].url
                                  : NoImage
                              }
                              alt="property"
                            />
                            <div className="property-wrapper">
                              <div className="left-block">
                                <div className="image-wrap">
                                  <i className="icon-camera"></i>
                                  <div className="image-count">
                                    1/{item.images?.length}
                                  </div>
                                </div>
                                <div className="property-view">
                                  <div className="video-wrap">
                                    <button>
                                      <i className="icon-play"></i>
                                    </button>
                                  </div>
                                  <div className="floor-plan">
                                    <button>
                                      <i className="icon-floor-plan"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="right-block">
                                <button>
                                  <i className={"icon-heart"} />
                                  <span className="span-btn-text d-none d-md-inline-block">
                                    {"save"}
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="property-tag">{item.status}</div>
                          </Link>
                        </div>
                        <div className="property-details">
                          <div className="property-name">
                            <Link
                              to={`/${"new-home-for-sale/"}${item?.slug}-${
                                item?.id
                              }/`}
                            >
                              {item?.display_address}
                            </Link>
                          </div>
                          <p>{item?.title}</p>
                          <div className="property-price">
                            €{item.price && item.price.toLocaleString()}
                          </div>
                          <div className="bottom-block">
                            {item.bedroom >= 1 && (
                              <div className="bedroom-count">
                                <i className="icon-bed"></i>
                                {item.bedroom}
                              </div>
                            )}
                            {item.bathroom >= 1 && (
                              <div className="bathroom-count">
                                <i className="icon-tub"></i>
                                {item.bathroom}
                              </div>
                            )}
                            {item.reception >= 1 && (
                              <div className="lounge-count d-none d-xl-flex">
                                <i className="icon-couch"></i>
                                {item.reception}
                              </div>
                            )}
                            <div className="property-area">
                              <i className="icon-cube"></i>
                              {`${"132"}m`}
                              <sup>2</sup>
                            </div>
                            <div className="property-label">
                              <img src={propertyLabel} alt="label" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              : ""}
          </Slider>
        </Container>
      </div>
    </React.Fragment>
  ) : null
}

export default FeatureNewHomeProperties
