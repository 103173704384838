import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
// import Dropdown from 'react-bootstrap/Dropdown'
import './OfficeFilterBar.scss';

const OfficeFilterBar = (props) => {
	const [department, setDepartment] = useState('Department');
	const departmentSelect = (e) => { setDepartment(e) }

	const county = props?.countylist?.map((items) => {
        return items.Filter_By_County;
    })
	county.push("Co. Roscommon");
	const removeDuplicates = (arr) => {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }
	const County = removeDuplicates(county).sort();

	return (
		<div className="filter-bar">
			<Container>
				<Row>
					<Col md="4">
						<p className="result-text">Showing branches across {props.branch === "Filter by county" ? "Ireland" : props.branch}</p>
					</Col>
					<Col md="8" className="d-md-flex align-items-md-center justify-content-lg-end">
						{/* <span className="filter-heading">Filter by:</span> */}
						<div className="filter-list">
							{/* <Dropdown onSelect={departmentSelect} className="select-option">
								<Dropdown.Toggle id="dropdown-basic">
								 {department}
						</Dropdown.Toggle>
						<Dropdown.Menu>
								<Dropdown.Item eventKey="Department">Department</Dropdown.Item>
								<Dropdown.Item eventKey="Department-2">Department-2</Dropdown.Item>
								<Dropdown.Item eventKey="Department-3">Department-3</Dropdown.Item>
							</Dropdown.Menu>
					</Dropdown> */}
					{props.view === "list" ?
										<div className="map-wrap">
                                            <button type='button' onClick={() => props.viewSelect('map')}>
                                                <i className="icon-tag"></i>
                                                <span className="btn-text d-md-none">map</span>
                                                <span className="btn-text d-none d-md-inline-block">show on map</span>
                                            </button>
                                        </div>
										:
										props.view === "map" ? 
										<div className="map-wrap">
                                            <button type='button' onClick={() => props.viewSelect('list')}>
											<i className="icon-grid"></i>
                                                <span className="btn-text d-md-none">grid</span>
                                                <span className="btn-text d-none d-md-inline-block">show on grid</span>
                                            </button>
                                        </div>
										: null}
							<Dropdown onSelect={props.locationSelect} className="select-option">
								<Dropdown.Toggle id="dropdown-basic">
									{props.location}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => props.sort(props.offices)} eventKey="A-Z">A-Z</Dropdown.Item>
									<Dropdown.Item onClick={() => props.desort(props.offices)} eventKey="Z-A">Z-A</Dropdown.Item>

								</Dropdown.Menu>
							</Dropdown>
							<Dropdown onSelect={props.branchSelect} className="select-option">
								<Dropdown.Toggle id="dropdown-basic">
									{props.branch}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item eventKey="Filter by county">All</Dropdown.Item>

									{County?.length > 0 ? County.map((item, index) => {
										return (
											<Dropdown.Item eventKey={item}>{item}</Dropdown.Item>
										)
									})
										: null}

								</Dropdown.Menu>
							</Dropdown>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default OfficeFilterBar
