import React, { useState } from "react"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Container, Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import Slider from "react-slick"
import "./VideoTestimonials.scss"
import Content from "../../Content/Content"
import PlayVideo from "../../PlayVideo/PlayVideo"
import NoImage from "../../../images/no-image.png"

const VideoTestimonials = props => {
  const [isPlay, setIsPlay] = useState(false)
  const [videoUrl, setVideoURL] = useState(-1)

  const { data, Title } = props
  const playVideoHandler = video_url => {
    setIsPlay(true)
    setVideoURL(video_url)
  }
  var settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className="video-testimonial">
      <Container className="video-testimonial-section">
        <h1>{Title}</h1>
        <div>
          <Slider {...settings}>
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <div className="video-testimonial-container">
                    <div className="testimonial-image">
                      <picture>
                        {item?.image?.url ? (
                          <img
                            src={item?.image?.url}
                            alt="video-testimonials"
                          />
                        ) : (
                          <img src={NoImage} alt="no-img" />
                        )}
                      </picture>

                      {item.Video_Url && (
                        <div className="btn-wrap">
                          <button
                            type="button"
                            onClick={() => playVideoHandler(item.Video_Url)}
                          >
                            <i className="icon-play"></i>
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="video-testimonial-content">
                      {item?.Content && <Content Content={item?.Content} />}
                    </div>
                    {/* <div>
                      {item.Secondary_Small_Image ? (
                        <img
                          className="company-image"
                          src={item.Secondary_Small_Image}
                          alt={item.Company_Name + " - DNG Estate Agents"}
                        />
                      ) : (
                        <img
                          src={NoImage}
                          className="company-no-image"
                          alt="no-img"
                        />
                      )}
                    </div> */}
                    <div>
                      <p className="company-name">{item.Company_Name}</p>
                      {/* <p className="sublime-text">{item.Text}</p> */}
                    </div>
                  </div>
                )
              })}
          </Slider>
          <PlayVideo
            isOpen={isPlay}
            stopPlay={setIsPlay}
            videoId={videoUrl}
            isAutoPlay={1}
          />
        </div>
      </Container>
    </div>
  )
}

export default VideoTestimonials
