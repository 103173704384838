/**
 * Number
 */
export const numberFormat = num => {
  if (!num) return 0
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
  return new Intl.NumberFormat("en-US", {}).format(num)
}

export const numberFormat1 = num => {
  if (!num) return ""
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
  return new Intl.NumberFormat("en-US", {}).format(num)
}


/**
 * parseInt
 */
export const filterNumber = str => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

/**
 * Retal yeild calculation
 */
export const rentalYield = (rate, rent) => {
  if (isNaN(rate) || isNaN(rent) || !rent || !rate) {
    return 0
  }

  if (rate === 0 || rent === 0) {
    return 0
  } else {
    return ((rent * 12) / rate) * 100
  }
}
