import React, { useState } from "react"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Container, Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import "./LicenseeForm.scss"
import LicenseForm from "../../forms/licensee"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Modal from "react-bootstrap/Modal"
import {URLS} from "../../common/site/constants";

const LicenseeForm = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        becomeALicensee(publicationState: LIVE) {
          Image {
            alternativeText
            url
          }
          Name
          Email_id
          Phone_number
          Position

          Become_a_Francise_Teams {
            Name
            id
            team {
              Name
              Email
              Designation
              Image {
                url
                alternativeText
              }
              imagetransforms
              Phone
              URL
            }
            
          }
        }
      }
    }
  `)

  const [show, setShow] = useState(false)
  const [team_details, setTeamDetails] = useState("")
  const handleClose = () => setShow(false)
  const handleShow = (team_det) => {
    setShow(true)
    setTeamDetails(team_det)
  }

  const licenseeData = data?.glstrapi?.becomeALicensee

  const image_url = licenseeData?.Image.url
  const name = licenseeData?.Name
  const position = licenseeData?.Position
  const phone_num = licenseeData?.Phone_number
  const email = licenseeData?.Email_id




  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="become-licensee-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
          id="licensee-form-component"
        >
          <Container className="become-licensee-section">
            <motion.h1 variants={contentItem}>
              <ReactMarkdown
                source={props.Title}
                allowDangerousHtml
                className="content"
              />
            </motion.h1>

            <motion.div variants={contentItem}>
              <ReactMarkdown
                source={props.Content}
                allowDangerousHtml
                className="content"
              />
            </motion.div>

            <Row>
              <Col>
                <motion.div className="licensee-head" variants={contentItem}>
                  {licenseeData?.Become_a_Francise_Teams?.length > 0 && licenseeData?.Become_a_Francise_Teams.map((item, index) => {
                    return(
                      <div className="licensee-team">
                        <div className="team-img">
                          <img src={item?.team?.Image?.url} alt="licensee-image" />
                        </div>
                        <div className="licensee-person-data">
                          <p className="licensee-person-name"><Link to={`${URLS.Team.alias}/${item?.team?.URL}/`}>{item.Name}</Link> </p>
                          <p clasName="position">{item?.team?.Designation}</p>
    
                          <div className="person-contact person">
                            {item?.team?.Phone && (
                              <Link
                                href={"tel:" + item?.team?.Phone}
                                className="phone-number"
                              >
                                <i className="icon-phone"></i> {item?.team?.Phone}
                              </Link>
                            )}
                            <a
                              href="javascript:;"
                              onClick={() => handleShow(item?.team)}
                              className="mail-text d-none d-xl-flex"
                            >
                              <i className="icon-mail"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  
                </motion.div>

                <div>
                  <LicenseForm email_id={email} buttonType={true} formName={props.Title.replace( /(<([^>]+)>)/ig, '')}/>
                </div>
              </Col>
            </Row>

            <Modal
              show={show}
              centered={true}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
            >
              <Modal.Header closeButton className="contact-close-btn">
                <Modal.Title className="w-100">
                  Email to {team_details.Name}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="form">
                <LicenseForm email_id={team_details.Email} buttonType={true}  />
              </Modal.Body>
            </Modal>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default LicenseeForm
