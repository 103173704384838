import { Link } from 'gatsby';
import React, { Fragment, useState } from 'react';
import opinionImage1 from '../../../../images/opinion-1.jpg';
import opinionImage2 from '../../../../images/opinion-2.jpg';
import opinionImage3 from '../../../../images/opinion-3.jpg';
import opinionImage4 from '../../../../images/opinion-4.jpg';
import PlayVideo from '../../../PlayVideo/PlayVideo';
// import ScrollAnimation from 'react-animate-on-scroll';
import { inViewOptions, contentItemStagger, contentItem, fadeInFromLeft, delayItemsFromBottom } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown"

import moment from 'moment';
const opinionData = [
	{
		id: 1,
		image: opinionImage1,
		videoId: 'V8ksSGxo6no',
		name: 'Residential',
		description: 'Where would you buy your ideal starter home in South Dublin'
	},
	{
		id: 2,
		image: opinionImage2,
		videoId: '',
		name: 'Auctions',
		description: 'DNG Market Review South West Region 2019 & Outlook 2020'
	},
	{
		id: 3,
		image: opinionImage3,
		videoId: '',
		name: 'Residential',
		description: 'Where would your ideal starter home be in West Dublin'
	},
	{
		id: 4,
		image: opinionImage4,
		videoId: 'V8ksSGxo6no',
		name: 'DNG',
		description: 'Introducing our new Head of Lettings Management Andrew Symonds'
	},

]

const OpinionListGridVideo = (props) => {
	const [opinionList, setOpinionList] = useState(opinionData);
	const [isPlay, setIsPlay] = useState(false);

	const playVideoHandler = () => {
		setIsPlay(true);
	}

	return (
		<InView {...inViewOptions}>
			{({ ref, inView }) => (
				<motion.div
					ref={ref}
					initial="hidden"
					animate={inView ? "visible" : "hidden"}
					variants={contentItemStagger}

				>
					<ul className="opinion-list grid">
						{props.news.map((item, index) => (
							<>
							
							<motion.li key={index} custom={index} variants={delayItemsFromBottom} className="opinion-item">
								<div className="left-block">
									<div className="img-zoom img-wrap">
										<img src={item.Image?.url} alt={item.Image?.alternativeText+" - DNG Estate Agents"} />
										{item.Video_Url && <div className="btn-wrapper">
											<button type="button" onClick={playVideoHandler}>
												<i className="icon-play"></i>
											</button>
										</div>}
									</div>
								</div>
								<div className="right-block">
									<div className="name d-none d-md-block">{moment(item?.Date).format('DD MMMM YYYY')}</div>
									<a onClick={playVideoHandler} className="detail">{item.Title}</a>
								</div>
								<PlayVideo
									isOpen={isPlay}
									stopPlay={setIsPlay}
									videoId={item.Video_Url}
									isAutoPlay={1}
								/>
							</motion.li>
							</>
						))
						}
					</ul>
				</motion.div>
			)}
		</InView>
	)
}

export default OpinionListGridVideo;
