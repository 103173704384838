import React, { useRef, useState, useEffect } from "react"
/**
 * Components
 */
import { Link } from "gatsby"
// import axios from "axios"
import StaticBannerCenter from "@Components/StaticPageTwo/StaticBanner/StaticBanner"
import FilterBar from "@Components/StaticPageTwo/FilterBar/FilterBar"
import OfficeFilterBar from "@Components/StaticPageTwo/OfficeFilterBar/OfficeFilterBar"
import OfficeList from "@Components/StaticPageTwo/OfficeList/OfficeList"
import { useWindowSize } from "../hooks/window-size"
import { isMacOs, isSafari } from "react-device-detect"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"

import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { navigate } from "@reach/router"
import {
  ScrollingProvider,
  Section,
  useScrollSection,
  useScrollSections,
} from "react-scroll-section"
import { Valuation_Page_Url } from "@Components/common/site/constants"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import VideoTestimonials from "./Home/VideoTestimonial/VideoTestimonials"
import RentalyieldCalc from "./calculators/rentalyieldcalc"
import PlayVideo from "./PlayVideo/PlayVideo"
const MotionCol = motion(Col)
const MotionRow = motion(Row)
function getUnique(array, key) {
  if (typeof key !== "function") {
    const property = key
    key = function (item) {
      return item[property]
    }
  }
  return Array.from(
    array
      .reduce(function (map, item) {
        const k = key(item)
        if (!map.has(k)) map.set(k, item)
        return map
      }, new Map())
      .values()
  )
}


const Modules = props => {
  const data = useStaticQuery(graphql`
    query {
      allAreas {
        edges {
          node {
            AreaName
          }
        }
      }
    
      glstrapi {
        departments(publicationState: LIVE, sort: "Name:ASC") {
          Name
        }
        offices(
          sort: "Office_Name:ASC"
          publicationState: LIVE
          where: { Publish: true }
        ) {
          id
          imagetransforms
          Branch_Location
          Filter_By_County
          Office_Name
          Address
          Thumbnail {
            alternativeText
            url
          }
          Banner_Image {
            alternativeText
            url
          }
          choose_areas {
            AreaName
          }
          City
          Contact_No
          County
          Email
          Head_Office_Branch_Location
          IsHeadOffice
          Longitude
          Latitude
          Publish
          URL
          Sort
        }
      }
    }
  `)
  //   const GET_AREAS_LIST = gql`
  //   query GetAreas(){
  //     areas(){
  //       AreaName
  //   }
  // }
  // `;
  //   const { loading, error, areasdata } = useQuery(GET_AREAS_LIST, {
  //     variables: {  }
  //   });

  const { state, pathname } = useLocation()

  const [offices, setoffices] = useState(data.glstrapi?.offices)
  const [areas, setareas] = useState(data?.allAreas?.edges)
  const [officeslist, setofficeslist] = useState(data.glstrapi?.offices)
  const [windowWidth] = useWindowSize()
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }
  const [searchvalue, setsearchvalue] = useState("")
  const [searchvalueTeam, setsearchvalueTeam] = useState("")

  useEffect(() => {

    // getPropertyData()
    //console.log("searchvalue 2", sessionStorage.getItem("searchvalue"))

    const homePagePath=typeof window!==undefined&& window.location.pathname;
    
    setsearchvalue(
      sessionStorage.getItem("searchvalue") !== null && homePagePath!=="/"&&homePagePath!=="/why-dng/"&&homePagePath!=="/about-dng-estate-agents/become-a-franchisee/"&&
      homePagePath!=="/services/become-a-franchisee/why-choose-dng-franchise/"&&homePagePath!=="/services/valuations/why-dng-valuations/"&&homePagePath!=="/services/valuations/"
        ? sessionStorage.getItem("searchvalue")
        : ""
    )
    if (sessionStorage.getItem("searchvalue") !== null) {
      // var arr = [];
      // data.glstrapi?.offices.filter(list => list.choose_areas?.map(c => c.AreaName?.toLowerCase()).includes(sessionStorage.getItem("searchvalue"))).map((item, index) => {
      //   arr.push(item)
      // })

      var office_list = data.glstrapi?.offices.filter(
        list =>
          list.Office_Name?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.County?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.Branch_Location?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.Address?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.choose_areas?.some(c =>
            c.AreaName?.toLowerCase().includes(
              sessionStorage.getItem("searchvalue")
            )
          )
      )

      setofficeslist(office_list)
      // sessionStorage.removeItem("searchvalue")
    } else {
      setofficeslist(data.glstrapi?.offices)
      sort(data.glstrapi?.offices)
      setareas(data?.allAreas?.edges)
      // console.log(data.glstrapi?.offices)
    }
    if (data.glstrapi?.offices) {
      setcountylist(getUnique(data.glstrapi?.offices, "County"))
    }
    if (data?.allTeams?.edges) {
      setbranchlist(getUnique(data?.allTeams?.edges, "Offices"))
    }
  }, [])
  // if(url !== '/contact/branch-finder'){
  //     sessionStorage.removeItem('searchvalue')
  // }

  const [countylist, setcountylist] = useState([])
  const [branchlist, setbranchlist] = useState([])

  const [showlist, setshowlist] = useState(false)
  const seearchonClink = () => {
    setshowlist(true)
  }
  const onleavesearch = e => {
    if (!searchvalue) {
      setshowlist(false)
    }
  }

  const seearchonLeave = () => {
    setshowlist(false)
  }
  const seearchonClose = () => {
    setshowlist(false)
    // setsearchEnterOn(false)
    setsearchvalue("")
    sessionStorage.removeItem("searchvalue")
    setofficeslist(data.glstrapi?.offices)
    setareas(data?.allAreas?.edges)
    if (isAscending) {
      sort(data.glstrapi?.offices)
    } else {
      desort(data.glstrapi?.offices)
    }
  }
  const sort = e => {
    const sortval = e.sort((a, b) => a.Office_Name.localeCompare(b.Office_Name))
    setofficeslist(sortval)
    setAscending(true)
  }
  // const istsort = (e) => {
  //   const sortval = e.sort((a, b) =>
  //     a.Branch_Location.localeCompare(b.Branch_Location)
  //   )
  //   setofficeslist(sortval)
  //   setAscending(true)
  // }
  const [isAscending, setAscending] = useState(true)
  const desort = e => {
    const sortval = e.sort((a, b) => b.Office_Name.localeCompare(a.Office_Name))

    setofficeslist(sortval)
    setAscending(false)
  }
  const seearchonSubmit = e => {
    e.preventDefault()
    setshowlist(false)
      window.scrollTo({
        top: 200,
        left: 0,
        behavior: "smooth",
      })
    // console.log(offices.filter(list => list.choose_areas?.map(c => c.AreaName?.toLowerCase()).includes(sessionStorage.getItem("searchvalue"))))
    //console.log("searchvalue", sessionStorage.getItem("searchvalue"))
    if (
      sessionStorage.getItem("searchvalue") !== null &&
      sessionStorage.getItem("searchvalue") !== ""
    ) {
      var office_list = data.glstrapi?.offices.filter(
        list =>
          list.Office_Name?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.County?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.Branch_Location?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.Address?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.choose_areas?.some(c =>
            c.AreaName?.toLowerCase().includes(
              sessionStorage.getItem("searchvalue")
            )
          )
      )

      // if(office_list.length === 0){
      //   office_list = data.glstrapi?.offices.filter(list => (list.Office_Name?.toLowerCase()).includes(sessionStorage.getItem("searchvalue")));

      // }
      
      setofficeslist(office_list)
      if (props.Page?.Pagename === "Home") {
        navigate("/contact-dng-estate-agents/branch-finder")
      } else if (props?.Page?.Pagename === "Why DNG") {
        navigate("/contact-dng-estate-agents/branch-finder")
      } else if(props?.Page?.Pagename==="Become a Franchisee"){
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      else if(props?.Page?.Pagename==="Why Choose DNG Franchise"){
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      else if(props?.Page?.Pagename==="Why DNG Valuations"){
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      else if(props?.Page?.Pagename==="Valuations"){
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      else {
      }
    } else {
      if (props.Page?.Pagename === "Home") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if (props?.Page?.Pagename === "Why DNG") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if(props?.Page?.Pagename==="Become a Franchisee"){
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if(props?.Page?.Pagename==="Why Choose DNG Franchise"){
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if(props?.Page?.Pagename==="Why DNG Valuations"){
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if(props?.Page?.Pagename==="Valuations"){
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      

      // window.scrollTo(0, 0)
     
      setofficeslist(data.glstrapi?.offices)
      
    }
    // setBranch(searchvalue)
  }

  const [showteam, setshowteam] = useState(false)
  const seearchonClinkTeam = () => {
    setshowteam(true)
  }
  const onleavesearchTeam = e => {
    if (!searchvalueTeam) {
      setshowteam(false)
    }
  }

  const seearchonLeaveTeam = () => {
    setshowteam(false)
  }
  const seearchonCloseTeam = () => {
    setshowteam(false)
    // setsearchEnterOn(false)
    setsearchvalueTeam("")
    // setteamBranch("All Branch")
    // setteamLocation("All Location")
    sessionStorage.removeItem("searchvalueTeam")
    // setteams(data?.allTeams?.edges)
  }

  const seearchonChangeTeam = e => {
    setsearchvalueTeam(e.target.value.toLowerCase())
    sessionStorage.setItem("searchvalueTeam", e.target.value.toLowerCase())
  }

 

  const [location, setLocation] = useState("A-Z")
  const locationSelect = e => {
    setLocation(e)
  }
  const [view, setView] = useState("list")
  const viewSelect = e => {
    setView(e)
  }

  // const [teamDepartment, setteamDepartment] = useState("All Department")
  // const teamDepartmentSelect = e => {
  //   setsearchvalueTeam("")
  //   setteamDepartment(e)
  //   setteamLocation('All Location')
  //   setteamBranch("All Branch")
  //   var depname = e === "All Department" ? "" : e.toLowerCase()
  //   setteams(data?.allTeams?.edges?.filter(list => list.offices?.some(c => c?.office_departments?.some(d => d.Name?.toLowerCase().includes(depname)))))
  // }

  const [isPlay, setIsPlay] = useState(false)

  const [branch, setBranch] = useState("Filter by county")
  const branchSelect = e => {
    setBranch(e)
    const val = data.glstrapi?.offices?.filter(list =>
      list?.County?.toLowerCase().includes(
        e === "Filter by county" ? "" : e.toLowerCase()
      )
    )
    if (isAscending) {
      val.sort((a, b) => a.Office_Name.localeCompare(b.Office_Name))
    } else {
      val.sort((b, a) => a.Office_Name.localeCompare(b.Office_Name))
    }
    setofficeslist(val)
    setsearchvalue("")
    sessionStorage.removeItem("searchvalue")
    setareas(data?.allAreas?.edges)
  }

  const seearchonChange = e => {
    setsearchvalue(e.target.value.toLowerCase())
    sessionStorage.setItem("searchvalue", e.target.value.toLowerCase())
    setareas(
      data?.allAreas?.edges?.filter(list =>
        list?.node?.AreaName.toLowerCase().includes(
          e.target.value.toLowerCase()
        )
      )
    )
  }

  

  return (
    <div className={`${isSafari || isMacOs ? "mac-saf" : ""}`}>
      <ScrollingProvider scrollBehavior="smooth">
        {windowWidth > 768 && props.Page?.Pagename !== "Home" && (
          <Breadcrumbs Page={props.Page?.Pagename} alias={props.Page?.Alias} />
        )}
        {props.Page?.Banner_Type === "Office" ? (
          <StaticBannerCenter
            executeScroll={executeScroll}
            imagetransforms={props?.Page?.imagetransforms}
            id={props?.Page?.id}
            Banner_Image={props.Page?.Banner_Image}
            Banner_Content={props.Page?.Banner_Content}
            Banner_Title={props.Page?.Banner_Title}
            Banner_Type={props.Page?.Banner_Type}
            Show_Form={props.Page?.Show_Form}
            Show_Review={props.Page?.Show_Review}
            Pagename={props.Page?.Pagename}
            Cta_Links={props.Page?.Cta_Links}
            areas={areas}
            offices={
              searchvalue
                ? // ? offices.filter(
                  //   list =>
                  //     list.Branch_Location?.toLowerCase().includes(searchvalue) ||
                  //     list.County?.toLowerCase().includes(searchvalue)
                  // )
                  data.glstrapi?.offices.filter(list =>
                    list.choose_areas?.some(c =>
                      c.AreaName?.toLowerCase().includes(
                        sessionStorage.getItem("searchvalue")
                      )
                    )
                  )
                : offices
            }
            setoffices={setoffices}
            officeslist={officeslist}
            showlist={showlist}
            seearchonChange={seearchonChange}
            seearchonClink={seearchonClink}
            onleavesearch={onleavesearch}
            seearchonClose={seearchonClose}
            seearchonLeave={seearchonLeave}
            seearchonSubmit={seearchonSubmit}
            searchvalue={searchvalue}
            setsearchvalue={setsearchvalue}
            setshowlist={setshowlist}
            showteam={showteam}
            seearchonChangeTeam={seearchonChangeTeam}
            seearchonClinkTeam={seearchonClinkTeam}
            onleavesearchTeam={onleavesearchTeam}
            seearchonCloseTeam={seearchonCloseTeam}
            seearchonLeaveTeam={seearchonLeaveTeam}
            // seearchonSubmitTeam={seearchonSubmitTeam}
            searchvalueTeam={searchvalueTeam}
            setsearchvalueTeam={setsearchvalueTeam}
          />
        ) : null}
        {props.Modules && props.Modules?.length > 0
          ? props.Modules.map((Module, i) => {
              return (
                <div key={i}>
                  
                  {Module.Choose_Collection === "offices" && (
                    <>
                      <OfficeFilterBar
                        countylist={countylist}
                        offices={officeslist ? officeslist : []}
                        officeslist={data.glstrapi?.offices}
                        setofficeslist={setofficeslist}
                        branchSelect={branchSelect}
                        branch={branch}
                        locationSelect={locationSelect}
                        location={location}
                        count={officeslist?.length}
                        desort={desort}
                        sort={sort}
                        view={view}
                        viewSelect={viewSelect}
                      />

                      <OfficeList
                        isAscending={isAscending}
                        offices={officeslist ? officeslist : []}
                        setoffices={setoffices}
                        location={location}
                        view={view}
                        viewSelect={viewSelect}

                        // postsToShow={postsToShow}
                      />
                    </>
                  )}


                
                </div>
              )
            })
          : ""}
      </ScrollingProvider>
    </div>
  )
}
export default Modules
