import React from "react";
import {ReviewStar} from "./../../icon/icon";
const GetRating = (props) => {
return (
<>
<ul className="ratings">
{props.starRating == "ONE" &&
<li>
<ReviewStar />
</li>
}
{props.starRating == "TWO" &&
<>
<li>
<ReviewStar /></li>
<li>
<ReviewStar /></li>
</>
}
{props.starRating == "THREE" &&
<>
<li>
<ReviewStar /></li>
<li>
<ReviewStar /></li>
<li>
<ReviewStar />
</li>
</>
}

{props.starRating == "FOUR" &&
<>
<li>
<ReviewStar />
</li>
<li>
<ReviewStar />
</li>
<li>
<ReviewStar />
</li>
<li>
<ReviewStar />
</li>
</>
}
{props.starRating == "FIVE" &&
<>

<li>
<ReviewStar />
</li>
<li>
<ReviewStar />
</li>
<li>
<ReviewStar />
</li>
<li>
<ReviewStar />
</li>
<li>
<ReviewStar />
</li>
</>
}
</ul>
</>
)
}
export default GetRating;
