import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
//import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/img6.jpg"
import Slider from "react-slick"
import "./RelatedNews.scss"
import ReactMarkdown from "react-markdown"
import GetURL from "@Components/common/site/get-url"
import { useWindowSize } from "../../../hooks/window-size"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import PlayVideo from "../../../Components/PlayVideo/PlayVideo"
import { InView } from "react-intersection-observer"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { NEWS_PAGE_URL } from "@Components/common/site/constants"
import NoImage from "../../../images/no-image.png"

import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const RELATED_NEWS = gql`
  query GetNews($Title: String!) {
    blogs(sort: "Date:desc", where: { URL_ne: $Title }) {
      id
      imagetransforms
      Title
      URL
      id
      Date
      Video_Url
      Tail_Image {
        url
      }
      blog_categories {
        Category_Name
      }
      Banner_Image {
        alternativeText
        url
      }
    }
  }
`
const RelatedNews = props => {
  const [isPlay, setIsPlay] = useState(false)

  const playVideoHandler = () => {
    setIsPlay(true)
  }
  const {
    loading: relatednewsloading,
    error: relatednewserror,
    data: relatednews,
  } = useQuery(RELATED_NEWS, {
    variables: { Title: props.CurrentNews, _id: props.CurrentCategory },
  })
  const MotionCol = motion(Col)
  const MotionRow = motion(Row)
  const [windowWidth] = useWindowSize()
  const AdvantageList = [
    {
      Images: Image1,
      Advantage: "Why Sell with DNG",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Branch Finder",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Guide to Selling",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Customer Reviews",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
  ]
  var settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  const ImageRender = ({ alt, image_url, node, processedImages }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="blog.Tail_Image.tail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }
  return (
    <div className="relatednews-block opinion">
      <Container>
        <Row>
          <InView {...inViewOptions}>
            {({ ref, inView }) => (
              <MotionCol
                ref={ref}
                lg="12"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={contentItemStagger}
              >
                <motion.h2 variants={contentItem}>
                  other insight that may interest you
                </motion.h2>
              </MotionCol>
            )}
          </InView>
        </Row>
        {/* {windowWidth < 1199 && */}
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.ul
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={contentItemStagger}
            >
              <Slider className="opinion-list grid overright" {...settings}>
                {relatednews?.blogs?.map((item, index) => {
                  let processedImages = JSON.stringify({})
                  if (item?.imagetransforms?.Tail_Image_Transforms) {
                    processedImages = item.imagetransforms.Tail_Image_Transforms
                  }
                  return (
                    <motion.li
                      key={index}
                      custom={index}
                      variants={fadeInFromRight}
                      className="opinion-item"
                    >
                      <div className="left-block">
                        <div className="img-zoom img-wrap">
                          <Link to={NEWS_PAGE_URL.alias + "/" + item.URL + "/"}>
                            {/* <img src={item.Tail_Image?.url ? item.Tail_Image?.url : NoImage} alt={item.Tail_Image?.alternativeText} /> */}
                            {item?.Tail_Image?.url ? (
                              <ImageRender
                                alt={
                                  item.Tail_Image?.alternativeText
                                    ? item.Tail_Image?.alternativeText +
                                      " - DNG Estate Agents"
                                    : item.Title + " - DNG Estate Agents"
                                }
                                processedImages={processedImages}
                                image_url={item?.Tail_Image?.url}
                                node={item}
                              />
                            ) : (
                              <img
                                src={NoImage}
                                alt={item.Tail_Image?.alternativeText}
                              />
                            )}
                          </Link>
                          {item.Video_Url && (
                            <div className="btn-wrapper">
                              <button type="button" onClick={playVideoHandler}>
                                <i className="icon-play"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="right-block">
                        <div className="name d-none d-md-block">
                          {item?.blog_categories?.map((list, index) => (
                            <>
                              {list.Category_Name}
                              {index === item?.blog_categories?.length - 1
                                ? ""
                                : ", "}
                            </>
                          ))}
                        </div>
                        <Link
                          to={NEWS_PAGE_URL.alias + "/" + item.URL + "/"}
                          className="detail"
                        >
                          {item.Title}
                        </Link>
                      </div>
                      <PlayVideo
                        isOpen={isPlay}
                        stopPlay={setIsPlay}
                        videoId={item.Video_Url}
                        isAutoPlay={1}
                      />
                    </motion.li>
                    // 				<motion.div className="opinion-item" key={index}
                    // 				custom={index} variants={delayItemsFromBottom} className="advantage-item">
                    // 					<GetURL class="tail-link-absolute" alias={item.URL} />
                    // 					{item.Banner_Image?.url &&
                    // 						<picture>
                    // 							<img src={item.Banner_Image?.url} alt={item.Banner_Image?.alternativeText} />
                    // 						</picture>
                    // 					}
                    // 					<div className="advantage-info">
                    // 					<div className="right-block">
                    // 	<div className="name d-none d-md-block">{item?.blog_categories?.map((list, index) => (
                    // 		<>{list.Category_Name}{index === item?.blog_categories?.length - 1 ? '' : ', '}</>
                    // 	))}</div>
                    // 	<Link to={NEWS_PAGE_URL.alias+'/'+item.URL} className="detail">{item.Title}</Link>
                    // </div>
                    // 						{item?.Content && <ReactMarkdown source={item?.Content} allowDangerousHtml />}
                    // 						<GetURL label={item.Explore_Cta?.Label} alias={item.Explore_Cta?.Url?.Alias} />
                    // 					</div>
                    // 				</motion.div>
                  )
                })}
              </Slider>
            </motion.ul>
          )}
        </InView>
        {/* } */}
        {/* {windowWidth > 1199 &&
					<InView {...inViewOptions}>
						{({ ref, inView }) => (
							<MotionRow
								ref={ref}
								initial="hidden"
								animate={inView ? "visible" : "hidden"}
								variants={contentItemStagger}
							>
								{props.Explore_Tails?.map((item, index) => (
									<MotionCol key={index}
										custom={index}
										variants={contentItem} md="6" xl="3" className="d-flex mb-space our-explore">
										<div className="advantage-item">
										<GetURL class="tail-link-absolute" alias={item.Explore_Cta?.Url?.Alias} />
											{item.Image?.url &&
												<picture>
													<img src={item.Image?.url} alt={item.Image?.alternativeText} />
												</picture>
											}
											<div className="advantage-info">
												<h3>{item.Title}</h3>
												{item?.Content && <ReactMarkdown source={item?.Content} allowDangerousHtml />}
												<GetURL label={item.Explore_Cta?.Label} alias={item.Explore_Cta?.Url?.Alias} />
											</div>
										</div>
									</MotionCol>
								))
								}
							</MotionRow>
						)}
					</InView>
				} */}
      </Container>
    </div>
  )
}

export default RelatedNews
