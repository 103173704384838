import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./Youtube.scss"

const Youtube = () => {
  return (
    <section className="Youtube">
      <Row>
        <Col>
          <div className="valuation-banner">
            <div className="valuation-details">
              <i className="icon-youtube"></i>
              <h3>Subscribe to our youtube channels</h3>
            </div>
            <div className="valuation-cta">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UC8BFvc4eDC3AxS8zB-r_pfg"
                className="btn"
              >
                subscribe
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default Youtube
