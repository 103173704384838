import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import './ContactPage.scss';
import { inViewOptions, contentItemStagger, contentItem } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown"
import ContactForm from "@Components/forms/contact"
const ContactPage = (props) => {

	const submitHandler = event => {
		event.preventDefault();
	}
	return (
		<InView {...inViewOptions}>
		{({ ref, inView }) => (
			<motion.section
				className="contact-page"
				ref={ref}
				initial="hidden"
				animate={inView ? "visible" : "hidden"}
				variants={contentItemStagger}
				style={{ backgroundImage: `url(${props?.Banner_Image?.url})` }}
			>

			<Container>
				<Row>
					<Col sm="12">
						<div className="content-wrapper">
							<div className="valuation-left">
								<div className="form-wrap">
								{props?.Banner_Title && <motion.h2 variants={contentItem}>{props?.Banner_Title}</motion.h2>}
									{props?.Banner_Content && <motion.div variants={contentItem}><ReactMarkdown source={props?.Banner_Content} allowDangerousHtml /></motion.div>}
									<ContactForm />
											{/* <form onSubmit={submitHandler}>
												<div className="form-content">
													<div className="input-wrap">
														<label htmlFor="name">Name:</label>
														<input type="text" id="name"  class="form-control"/>
													</div>
													<div className="input-wrap">
														<label htmlFor="email">Email Address:</label>
														<input type="email" id="email"  class="form-control"/>
													</div>
													<div className="input-wrap">
														<label htmlFor="email">Email Address:</label>
														<input type="email" id="email"  class="form-control"/>
													</div>
													<div className="input-wrap d-none d-md-block">
														<label htmlFor="contact-number">Property Address:</label>
														<input type="text" id="contact-number"  class="form-control"/>
													</div>
													<div className="input-wrap d-md-none">
														<label htmlFor="enquiry-line">Line of Enquiry:</label>
														<select id="enquiry-line" class="form-control">
															<option value="1">General Enquiry</option>
															<option value="2">General Enquiry</option>
															<option value="3">General Enquiry</option>
														</select>
													</div>
													<div className="input-wrap d-md-none">
														<label htmlFor="message">Your Query:</label>
														<textarea id="message" placeholder="Write your message here..." />
													</div>
													<button className="btn">submit enquiry</button>
													<p>By clicking Submit, you agree to our <Link href="#"> Terms & Conditions </Link> and <Link href="#">Privacy Policy. </Link></p>
												</div>
											</form> */}
								</div>
							</div>
							{/* <div className="valuation-right">
								<h2>Why <span className="highlight">sell or let</span> with DNG?</h2>
								<ul class="our-speciality">
									<li>
										<div className="icon-wrap">
											<i className="icon-availability"></i>
										</div>
										<div className="speciality-text">
											<h4>Available 7 days a week</h4>
											<p>Lorem ipsum dolor sit amet consectetur adipisci.</p>
										</div>
									</li>
									<li>
										<div className="icon-wrap">
											<i className="icon-sold-out"></i>
										</div>
										<div className="speciality-text">
											<h4>12,000+ properties sold in 2020</h4>
											<p>Lipsum dolor sit amet, consectetur adipiscing elit.</p>
										</div>
									</li>
									<li>
										<div className="icon-wrap">
											<i className="icon-star"></i>
										</div>
										<div className="speciality-text">
											<h4>Rated 4.9/5 from Google</h4>
											<p>Lorem ipsum dolor sit amet consectetur adipisci.</p>
										</div>
									</li>
									<li>
										<div className="icon-wrap">
											<i className="icon-key"></i>
										</div>
										<div className="speciality-text">
											<h4>50,000 Registered Buyers</h4>
											<p>Lipsum dolor sit amet, consectetur adipiscing elit.</p>
										</div>
									</li>
									<li>
										<div className="icon-wrap">
											<i className="icon-road-sign"></i>
										</div>
										<div className="speciality-text">
											<h4>Unrivalled local knowledge</h4>
											<p>Lorem ipsum dolor sit amet consectetur adipisci.</p>
										</div>
									</li>
									<li>
										<div className="icon-wrap">
											<i className="icon-chair"></i>
										</div>
										<div className="speciality-text">
											<h4>80+ offices across Ireland</h4>
											<p>Lorem ipsum dolor sit amet consectetur adipisci.</p>
										</div>
									</li>
								</ul>
							</div> */}
						</div>
					</Col>
				</Row>
			</Container>
			</motion.section>
			)}
		</InView>
	)
}

export default ContactPage;
