import React, { useRef, useState, useEffect } from "react"
/**
 * Components
 */
import { Link } from "gatsby"
// import axios from "axios"
import Banner from "@Components/Home/Banner/Banner"
import StaticBanner from "@Components/Static/StaticBanner/StaticBanner"
import ContactPage from "@Components/PropertyDetails/ContactPage/ContactPage"
import AnalysisResearch from "@Components/StaticPageTwo/AnalysisResearch/AnalysisResearch"
import Express from "@Components/StaticPageTwo/Express/Express"
import Videos from "@Components/StaticPageTwo/Videos/Videos"
import ExploreGrid from "@Components/StaticPageTwo/Videos/ExploreGrid"
import FeatureNewHomeProperties from "@Components/PropertyDetails/FeatureNewHomeProperties/FeatureNewHomeProperties"
import BookValuation from "@Components/PropertyDetails/BookValuation/BookValuation"
import ServiceAdvisor from "@Components/Static/ServiceAdvisor/ServiceAdvisor"
import MarketingServices from "@Components/Static/MarketingServices/MarketingServices"
import OurAdvantage from "@Components/StaticPageTwo/OurAdvantage/OurAdvantage"
import OurAdvantageStatics from "@Components/Static/OurAdvantage/OurAdvantage"
import OurAdvantageServices from "@Components/StaticPageTwo/OurAdvantageServices/OurAdvantageServices"
import SubscribeForm from "@Components/StaticPageTwo/SubscribeForm/SubscribeForm"
import PeopleVideoList from "@Components/StaticPageTwo/PeopleVideoList/PeopleVideoList"
import CurrentVacancies from "@Components/StaticPageTwo/CurrentVacancies/CurrentVacancies"
import Cta from "@Components/Home/Cta/Cta"
import About from "@Components/Home/About/About"
import AboutGrid from "@Components/Home/About/AboutGrid"
import Testimonials from "@Components/Home/Testimonials/Testimonials"
import Opinion from "@Components/Home/Opinion/Opinion"
import Community from "@Components/Home/Opinion/Community"
import { useWindowSize } from "../hooks/window-size"
import { isMacOs, isSafari } from "react-device-detect"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"
import ContentBlock from "@Components/Static/ContentBlock/ContentBlock"
import ContactSidebar from "@Components/Static/ContactSidebar/ContactSidebar"

import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { navigate } from "@reach/router"
import ReviewsList from "@Components/Static/Reviews/reviews-listing"
import LicenseeForm from "./Static/Licensee/LicenseeForm"
import {
  ScrollingProvider,
  Section,
  useScrollSection,
  useScrollSections,
} from "react-scroll-section"
import { Valuation_Page_Url } from "@Components/common/site/constants"
import StaticSitePage from "./Static/TextContents/StaticSitePage"
import TrendingProperties from "@Components/PropertyDetails/TrendingProperties/trendingProperties"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import VideoTestimonials from "./Home/VideoTestimonial/VideoTestimonials"
import RentalyieldCalc from "./calculators/rentalyieldcalc"
import PlayVideo from "./PlayVideo/PlayVideo"
const MotionCol = motion(Col)
const MotionRow = motion(Row)
function getUnique(array, key) {
  if (typeof key !== "function") {
    const property = key
    key = function (item) {
      return item[property]
    }
  }
  return Array.from(
    array
      .reduce(function (map, item) {
        const k = key(item)
        if (!map.has(k)) map.set(k, item)
        return map
      }, new Map())
      .values()
  )
}

const DynamicMenu = () => {
  const sections = useScrollSections();
  // const homeSection = useScrollSection('advantage');
  // const aboutSection = useScrollSection('about');
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 68)
    })
  }, [])

  return (
    <div className={scroll ? "stick-menu-top-bk fixed" : "stick-menu-top-bk"}>
      <Container>
        <Row>
          <div className="stick-menu-top">
            <ul className="nav-sticky">
              {sections.map(({ id, onClick, selected }) => (
                <li
                  key={id}
                  onClick={onClick}
                  selected={selected}
                  className={selected ? "active" : ""}
                >
                  {id}
                </li>
              ))}
            </ul>
            <div className="btn-block">
              <a className="tel-btn" href="tel:01 4912600">
                <i className="icon-phone-white"></i> 01 4912600
              </a>
              <Link className="btn" to={Valuation_Page_Url.alias}>
                Request a Valuation
              </Link>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}
const Modules = props => {


  const [windowWidth] = useWindowSize()
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }
  const myRefjoin = useRef(null)
  const executeScrolljoin = () => {
    myRefjoin.current.scrollIntoView({ behavior: "smooth" })
  }

 

  const [isPlay, setIsPlay] = useState(false)

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  return (
    <div className={`${isSafari || isMacOs ? "mac-saf" : ""}`}>
      <ScrollingProvider scrollBehavior="smooth">
        {windowWidth > 768 && props.Page?.Pagename !== "Home" && (
          <Breadcrumbs Page={props.Page?.Pagename} alias={props.Page?.Alias} />
        )}
        {props.Page?.Pagename === "Why DNG" && <DynamicMenu />}
        {props.Page?.Banner_Type === "Full_Height" ? (
          <Banner
            Banner_Image={props.Page?.Banner_Image}
            Banner_Content={props.Page?.Banner_Content}
            Banner_Title={props.Page?.Banner_Title}
            Banner_Type={props.Page?.Banner_Type}
            Show_Form={props.Page?.Show_Form}
            Show_Review={props.Page?.Show_Review}
            Pagename={props.Page?.Pagename}
            Cta_Links={props.Page?.Cta_Links}
          />
        ) : props.Page?.Banner_Type === "Half_Height" ? (
          <StaticBanner
            executeScroll={executeScroll}
            Banner_Image={props.Page?.Banner_Image}
            Banner_Content={props.Page?.Banner_Content}
            Banner_Title={props.Page?.Banner_Title}
            Banner_Type={props.Page?.Banner_Type}
            Show_Form={props.Page?.Show_Form}
            Show_Review={props.Page?.Show_Review}
            Pagename={props.Page?.Pagename}
            Cta_Links={props.Page?.Cta_Links}
            imagetransforms={props?.Page?.imagetransforms}
            id={props?.Page?.id}
            customCss={props?.Page?.Custom_CSS_Class}
            Alias={props?.Page?.Alias}
          />
        ) : props.Page?.Banner_Type === "MarketProperty" ? (
          <MarketingServices
            executeScroll={executeScroll}
            Banner_Image={props.Page?.Banner_Image}
            Banner_Content={props.Page?.Banner_Content}
            Banner_Title={props.Page?.Banner_Title}
            Banner_Type={props.Page?.Banner_Type}
            Show_Form={props.Page?.Show_Form}
            Show_Review={props.Page?.Show_Review}
            Pagename={props.Page?.Pagename}
            Cta_Links={props.Page?.Cta_Links}
            Video_url={props.Page?.Video_url}
          />
        ) : props.Page?.Banner_Type === "Mortgage_Calculator" ||
          props.Page?.Banner_Type === "Book_a_Valuation" ||
          props.Page?.Banner_Type === "Request_a_Valuation" ||
          props.Page?.Banner_Type==="Client_Services"||
          props.Page?.Banner_Type === "Fair_deal" ? (
          <BookValuation
            Banner_Image={props.Page?.Banner_Image}
            Banner_Content={props.Page?.Banner_Content}
            Banner_Title={props.Page?.Banner_Title}
            Banner_Type={props.Page?.Banner_Type}
            Show_Form={props.Page?.Show_Form}
            Show_Review={props.Page?.Show_Review}
            Pagename={props.Page?.Pagename}
            Cta_Links={props.Page?.Cta_Links}
            Video_url={props.Page?.Video_url}
            Alias={props.Page?.Alias}
            branchDetails={props.branchDetails}
          />
        ) : props.Page?.Banner_Type === "Contact" ? (
          <ContactPage
            Banner_Image={props.Page?.Banner_Image}
            Banner_Content={props.Page?.Banner_Content}
            Banner_Title={props.Page?.Banner_Title}
            Banner_Type={props.Page?.Banner_Type}
            Show_Form={props.Page?.Show_Form}
            Show_Review={props.Page?.Show_Review}
            Pagename={props.Page?.Pagename}
            Cta_Links={props.Page?.Cta_Links}
            Video_url={props.Page?.Video_url}
          />
        )  : null}
        {props.Modules && props.Modules?.length > 0
          ? props.Modules.map((Module, i) => {
              return (
                <div key={i}>
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsContentBlock" && (
                    <div className="static-block grey">
                      <Container>
                        <Row>
                          <Col xl="7" className="col-xxl-8 col-custom-8">
                            <Row>
                              <InView {...inViewOptions}>
                                {({ ref, inView }) => (
                                  <MotionCol
                                    ref={ref}
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={contentItemStagger}
                                  >
                                    <ContentBlock Content={Module.Content} />
                                  </MotionCol>
                                )}
                              </InView>
                            </Row>
                            
                          </Col>
                          <InView {...inViewOptions}>
                            {({ ref, inView }) => (
                              <MotionCol
                                xl="5"
                                className="col-xxl-4 col-custom-4"
                                ref={ref}
                                initial="hidden"
                                animate={inView ? "visible" : "hidden"}
                                variants={contentItemStagger}
                              >
                                <ContactSidebar Alias={props.Page?.Alias} />
                               
                               {props.Page?.Alias==="about-dng-estate-agents"&&
                                <div className="about-page-thumbnail">
                                            <picture className="thumbnail">
                    {props.Page?.Video_url && (
                      <button
                        type="button"
                        className="play-btn"
                        onClick={playVideoHandler}
                      >
                        <i className="icon-play"></i>
                      </button>
                    )}
                    {props.Page.You_Tube_Thumbnail.url && (
                      <img
                        src={props.Page.You_Tube_Thumbnail.url}
                        alt={"ABOUT DNG Estate Agents"}
                      />
                    )}
                  </picture>
                                </div>
                              }

                            {props.Page?.Video_url && (
                              <PlayVideo
                                isOpen={isPlay}
                                stopPlay={setIsPlay}
                                videoId={props.Page?.Video_url}
                                isAutoPlay={1}
                              />
                            )}
                              </MotionCol>
                            )}
                          </InView>
                        </Row>
                      </Container>
                    </div>
                  )}
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsGetStarted" && (
                    <Cta
                      Title={Module.Title}
                      Content={Module.Content}
                      Design_Title={Module.Design_Title}
                      Get_Started_Cta_Links={Module.Get_Started_Cta_Links}
                    />
                  )}
                  {Module.Choose_Collection === "Statics" && (
                    <Section id={Module.Menu_Title}>
                      <OurAdvantageStatics Title={Module.Title} />
                    </Section>
                  )}
                  {
                    Module.Choose_Collection==="RentalYeild"&&(
                      <RentalyieldCalc
                      Title={Module.Title}
                      />
                    )
                  }
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsTestimonials" && (
                    <Testimonials
                      testimonials_data={Module.Add_Testimonials}
                      Title={Module.Title}
                    />
                  )}
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsVideoTestimonials" && (
                    <VideoTestimonials
                      data={Module.Testimonials_Video_Franchise}
                      Title={Module.Title}
                    />
                  )}
                  {Module.Choose_Collection === "Licensee" && (
                    <LicenseeForm
                      Choose_Collection={Module.Choose_Collection}
                      Content={Module.Content}
                      Title={Module.Title}
                    />
                  )}

                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsTextContent" && (
                    <StaticSitePage
                      TextContent={Module.TextContent}
                      Title={Module.Title}
                    />
                  )}

                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsAboutBlock" && (
                    <About
                      Content={Module.Content}
                      Title={Module.Title}
                      Video_Url={Module.Video_Url}
                      Tails={Module.Tails}
                      Image={Module.Image}
                      Tail_Type={Module.Tail_Type}
                      Align_type={Module.Align_type}
                      Background_Color={Module.Background_Color}
                      Choose_Team={Module.choose_teams}
                      PageName={props.Page?.Pagename}
                    />
                  )}
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsGridBlock" && (
                    <Section id={Module.Menu_Title}>
                      <AboutGrid
                        Content={Module.Content}
                        Video_Url={Module.Video_Url}
                        Image={Module.Image}
                        Tail_Type={Module.Tail_Type}
                        Align_Type={Module.Align_Type}
                        Menu_Title={Module.Menu_Title}
                      />
                    </Section>
                  )}
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsExploreGridTails" && (
                    <Section id={Module.Menu_Title}>
                      <ExploreGrid
                        Content={Module.Content}
                        Menu_Title={Module.Menu_Title}
                        Add_Explore_Grid={Module.Add_Explore_Grid}
                      />
                    </Section>
                  )}
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsServicesCta" &&
                    props.Page?.Pagename === "Services1" && (
                      <ServiceAdvisor
                        Content={Module.Content}
                        Title={Module.Title}
                        Tail_Block={Module.Tail_Block}
                      />
                    )}
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsServicesCta" &&
                    props.Page?.Pagename === "Services" && (
                      <OurAdvantageServices
                        Content={Module.Content}
                        Title={Module.Title}
                        Tail_Block={Module.Tail_Block}
                      />
                    )}
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsPeoplesBlock" && (
                    <Section id={Module.Menu_Title}>
                      <PeopleVideoList
                        Content={Module.Content}
                        Title={Module.Title}
                        People_List={Module.People_List}
                        Menu_Title={Module.Menu_Title}
                        Sticky_Top_Menu={Module.Sticky_Top_Menu}
                        Image={Module.Image}
                        Video_Url={Module.Video_Url}
                      />
                    </Section>
                  )}
                  {Module.__typename ===
                    "GLSTRAPI_ComponentComponentsExploreBlock" && (
                    // <Section id="advantage">
                    <OurAdvantage
                      Title={Module.Title}
                      Explore_Tails={Module.Explore_Tails}
                      Image={Module.Image}
                    />
                    // </Section>
                  )}
                  {Module.Choose_Collection === "career" && (
                    <>
                      <div ref={myRef}></div>
                      <CurrentVacancies
                        Choose_Collection={Module.Choose_Collection}
                        Content={Module.Content}
                        Title={Module.Title}
                      />
                    </>
                  )}

                  {Module.Choose_Collection === "News" && (
                    <>
                      <Opinion
                        Choose_Collection={Module.Choose_Collection}
                        Content={Module.Content}
                        executeScrolljoin={executeScrolljoin}
                        Title={Module.Title}
                        isNewsPage={
                          props.Page?.Pagename === "News & Insight"
                            ? true
                            : false
                        }
                      />
                      {/* {props.Page?.Pagename === "News & Insight" && (
                        <>
                          <div ref={myRefjoin}></div>
                          <SubscribeForm type="News" />
                        </>
                      )} */}
                    </>
                  )}
                  {Module.Choose_Collection === "Reviews" && (
                    <ReviewsList
                      Choose_Collection={Module.Choose_Collection}
                      Content={Module.Content}
                      Title={Module.Title}
                    />
                  )}
                  {Module.Choose_Collection === "Community" && (
                    <>
                      <Community
                        Choose_Collection={Module.Choose_Collection}
                        Content={Module.Content}
                        Title={Module.Title}
                        isNewsPage={
                          props.Page?.Pagename === "News & Insight"
                            ? true
                            : false
                        }
                      />
                    </>
                  )}
                  {Module.Choose_Collection === "Research" && (
                    <>
                      <AnalysisResearch
                        executeScrolljoin={executeScrolljoin}
                        Content={Module.Content}
                        Title={Module.Title}
                      />
                      <div ref={myRefjoin}></div>
                      <SubscribeForm type="Research" />
                    </>
                  )}
                  {Module.Choose_Collection === "Express" && (
                    <>
                      <Express Content={Module.Content} Title={Module.Title} />
                      <SubscribeForm type="Express" />
                    </>
                  )}
                  {Module.Choose_Collection === "Videos" && (
                    <>
                      <Videos Content={Module.Content} Title={Module.Title} />
                      <SubscribeForm type="News" />
                    </>
                  )}
                  {Module.Choose_Collection === "NewHomes" && (
                    <FeatureNewHomeProperties
                      Content={Module.Content}
                      Title={Module.Title}
                    />
                  )}

                   {Module.Choose_Collection === "TrendingProperties" && (
                    <TrendingProperties
                     limit={10}
                    />
                  )}
                 
                 
                </div>
              )
            })
          : ""}
        {/* {props.Page?.Pagename === "Home" && <TrendingProperties limit={10} />} */}
      </ScrollingProvider>
    </div>
  )
}
export default Modules
