import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  Container,
  FormControl,
  Row,
  InputGroup,
  Col,
  Dropdown,
} from "react-bootstrap"
import axios from "axios"
import Modal from "react-bootstrap/Modal"
import TimeAgo from "react-timeago"
import frenchStrings from "react-timeago/lib/language-strings/en"
import buildFormatter from "react-timeago/lib/formatters/buildFormatter"
import GetRating from "./getrating"
//import ScrollAnimation from "react-animate-on-scroll"
import ContactForm from "@Components/forms/reviewscontact"
import { inViewOptions } from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import logoBlack from "../../../images/loading-buffering.gif"
import {token} from "@Components/forms/api/Api"

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4,
    },
  },
}

const items = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.3,
    },
  },
}

const MotionColumn = motion(Col)

const postsPerPage = 18
let arrayForHoldingPosts = []

const Ethics = props => {
  /**** set here local states */
  const [] = useState(0)

  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(18)

  const [] = useState(true)
  const [testimonials, setTestimonials] = useState([])

  const loopWithSlice = (start, end) => {
    const slicedPosts = testimonials.slice(start, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
  }

  const [show, setShow] = useState(false)
  const [loading, setloading] = useState(false)
  const handleClose = () => setShow(false)
  const [reviewid, setReviewid] = useState(false)
  const formatter = buildFormatter(frenchStrings)
  const handleShow = event => {
    setReviewid(event)
    setShow(true)
  }
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "multipart/form-data"
        }
      }) // could be from env files
      setTestimonials(data)
      setloading(false)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
      setloading(false)
    }
  }
  useEffect(() => {
    loopWithSlice(0, postsPerPage)
  }, [testimonials])

  useEffect(() => {
    setloading(true)
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url)
  }, [])

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <section className="review-listing">
            {loading && (
              <div className="reviews gql-loading-screen">
                <Container>
                  <div className="gql-loading">
                    <img
                      className="logo-white loader-logo"
                      src={logoBlack}
                      alt="logo"
                    />
                  </div>
                </Container>
              </div>
            )}
            <React.Fragment>
              <Container>
                {/* <ScrollAnimation animateIn="fadeInUp" animateOnce={true}> */}
                  <Row>
                    <MotionColumn
                      lg={8}
                      sm={6}
                      variants={items}
                      className="review-list-items"
                    >
                      {postsToShow.map(review => {
                        //if(review.comment) {
                        if (
                          (review.starRating === "FOUR" &&
                            review.comment !== undefined) ||
                          (review.starRating === "FIVE" &&
                            review.comment !== undefined)
                        ) {
                          return (
                            <>
                              <div className="review-wrap">
                                <GetRating starRating={review.starRating} />
                                <span className="review-desc">
                                  {review?.comment}
                                  {/* {review.comment && review.comment.substring(0, 120)}
                                                                {review.comment && review.comment.length > 120 ? '... ' : ''}
                                                                {review.comment && review.comment.length > 120 &&
                                                                    <a href="javascript:void(0);" className="btn-text-link" onClick={() => handleShow(review.id)}>Read More</a>
                                                                } */}
                                </span>
                                <div className="reviewer-detail">
                                  <img
                                    src={review.reviewer.profilePhotoUrl}
                                    alt={
                                      review.reviewer.displayName +
                                      " - DNG Estate Agents"
                                    }
                                  />
                                  <span className="review-date">
                                    <span className="name">
                                      {review.reviewer.displayName}
                                    </span>
                                    <div>
                                      <TimeAgo
                                        date={review.createTime}
                                        formatter={formatter}
                                      />
                                    </div>
                                  </span>
                                </div>

                                {reviewid && reviewid == review.id && (
                                  <>
                                    <Modal
                                      show={show}
                                      size="lg"
                                      centered={true}
                                      onHide={handleClose}
                                      backdrop="static"
                                      keyboard={false}
                                      dialogClassName="modal-90w modal-form reviewPopup"
                                    >
                                      <div className="review-wrap">
                                        <Modal.Header
                                          closeButton
                                          className="contact-close-btn"
                                        >
                                          <Modal.Title className="w-100">
                                            {review.reviewer.displayName}
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <span className="review-date">
                                            <TimeAgo
                                              date={review.createTime}
                                              formatter={formatter}
                                            />
                                          </span>
                                          <GetRating
                                            starRating={review.starRating}
                                          />
                                          <div className="review-desc">
                                            {review.comment}
                                          </div>
                                        </Modal.Body>
                                      </div>
                                    </Modal>
                                  </>
                                )}
                              </div>
                            </>
                          )
                        }
                        //}
                      })}
                    </MotionColumn>
                    <Col lg={4} sm={6}>
                      <div className="sidebar-contact sidebar-bk contact-page">
                        <div className="dealer-cta d-none d-xl-flex">
                          <h4>looking to sell or Let?</h4>
                          <p>
                            Or perhaps you just need help with something? Fill
                            out the form and we’ll be in touch.
                          </p>
                          <ContactForm />
                        </div>
                      </div>
                    </Col>
                    {!loading && (
                      <Col lg="8">
                        <div className="review-load">
                          <button
                            className="btn-pagination btn btn-primary"
                            onClick={handleShowMorePosts}
                          >
                            Load more
                          </button>
                        </div>
                      </Col>
                    )}
                  </Row>
                {/* </ScrollAnimation> */}
              </Container>
            </React.Fragment>
          </section>
        </motion.section>
      )}
    </InView>
  )
}
export default Ethics
