import React, { useRef, useState, useEffect } from "react"
/**
 * Components
 */
import { Link } from "gatsby"
// import axios from "axios"
import StaticBannerCenter from "@Components/StaticPageTwo/StaticBanner/StaticBanner"
import FilterBar from "@Components/StaticPageTwo/FilterBar/FilterBar"
import OfficeFilterBar from "@Components/StaticPageTwo/OfficeFilterBar/OfficeFilterBar"
import PeopleList from "@Components/StaticPageTwo/PeopleList/PeopleList"
import { useWindowSize } from "../hooks/window-size"
import { isMacOs, isSafari } from "react-device-detect"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"

import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { navigate } from "@reach/router"
import {
  ScrollingProvider,
  Section,
  useScrollSection,
  useScrollSections,
} from "react-scroll-section"
import { Valuation_Page_Url } from "@Components/common/site/constants"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import VideoTestimonials from "./Home/VideoTestimonial/VideoTestimonials"
import RentalyieldCalc from "./calculators/rentalyieldcalc"
import PlayVideo from "./PlayVideo/PlayVideo"
const MotionCol = motion(Col)
const MotionRow = motion(Row)
function getUnique(array, key) {
  if (typeof key !== "function") {
    const property = key
    key = function (item) {
      return item[property]
    }
  }
  return Array.from(
    array
      .reduce(function (map, item) {
        const k = key(item)
        if (!map.has(k)) map.set(k, item)
        return map
      }, new Map())
      .values()
  )
}


const Modules = props => {
  const data = useStaticQuery(graphql`
    query {
      allAreas {
        edges {
          node {
            AreaName
          }
        }
      }
      allTeams {
        edges {
          node {
            _id
            imagetransforms {
              Image_Transforms
            }
            Designation
            Name
            Image {
              alternativeText
              url
            }
            Publish
            Phone
            Mobile_No
            URL
            Email
            Video_Url
            offices {
              Branch_Location
              Office_Name
            }
            Sort
          }
        }
      }
      glstrapi {
        departments(publicationState: LIVE, sort: "Name:ASC") {
          Name
        }
        offices(
          sort: "Office_Name:ASC"
          publicationState: LIVE
          where: { Publish: true }
        ) {
          id
          imagetransforms
          Branch_Location
          Office_Name
          Address
          Thumbnail {
            alternativeText
            url
          }
          Banner_Image {
            alternativeText
            url
          }
          choose_areas {
            AreaName
          }
          City
          Contact_No
          County
          Email
          Head_Office_Branch_Location
          IsHeadOffice
          Longitude
          Latitude
          Publish
          URL
          Sort
        }
      }
     
    }
  `)
  //   const GET_AREAS_LIST = gql`
  //   query GetAreas(){
  //     areas(){
  //       AreaName
  //   }
  // }
  // `;
  //   const { loading, error, areasdata } = useQuery(GET_AREAS_LIST, {
  //     variables: {  }
  //   });

  const { state, pathname } = useLocation()
  const [offices, setoffices] = useState(data.glstrapi?.offices)
  const [areas, setareas] = useState(data?.allAreas?.edges)
  const [officeslist, setofficeslist] = useState(data.glstrapi?.offices)
  const [departments, setdepartments] = useState(data.glstrapi?.departments)
  const [teams, setteams] = useState(data?.allTeams?.edges)
  const [searchEnter, setsearchEnter] = useState("")
  const [searchEnterOn, setsearchEnterOn] = useState("")
  const [windowWidth] = useWindowSize()
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }
  const [searchvalue, setsearchvalue] = useState("")
  const [searchvalueTeam, setsearchvalueTeam] = useState("")

  
  // if(url !== '/contact/branch-finder'){
  //     sessionStorage.removeItem('searchvalue')
  // }


  const [showlist, setshowlist] = useState(false)
  const seearchonClink = () => {
    setshowlist(true)
  }
  const onleavesearch = e => {
    if (!searchvalue) {
      setshowlist(false)
    }
  }

  const seearchonLeave = () => {
    setshowlist(false)
  }
  const seearchonClose = () => {
    setshowlist(false)
    // setsearchEnterOn(false)
    setsearchvalue("")
    sessionStorage.removeItem("searchvalue")
    setareas(data?.allAreas?.edges)
  }
  const [showteam, setshowteam] = useState(false)
  const seearchonClinkTeam = () => {
    setshowteam(true)
  }
  const onleavesearchTeam = e => {
    if (!searchvalueTeam) {
      setshowteam(false)
    }
  }

  const seearchonLeaveTeam = () => {
    setshowteam(false)
  }
  const seearchonCloseTeam = () => {
    setshowteam(false)
    // setsearchEnterOn(false)
    setsearchvalueTeam("")
    setteamBranch("All Branch")
    setteamLocation("All Location")
    sessionStorage.removeItem("searchvalueTeam")
    setteams(data?.allTeams?.edges)
  }

  const seearchonChangeTeam = e => {
    setsearchvalueTeam(e.target.value.toLowerCase())
    sessionStorage.setItem("searchvalueTeam", e.target.value.toLowerCase())
  }

  const seearchonSubmitTeam = e => {
    e.preventDefault()
    setshowteam(false)
    if (
      sessionStorage.getItem("searchvalueTeam") !== null &&
      sessionStorage.getItem("searchvalueTeam") !== ""
    ) {
      var team_filter = data?.allTeams?.edges.filter(
        list =>
          list?.node?.Name?.toLowerCase().includes(
            sessionStorage.getItem("searchvalueTeam")
          ) ||
          list?.node?.offices?.some(
            c =>
              c.Branch_Location?.toLowerCase().includes(
                sessionStorage.getItem("searchvalueTeam")
              ) ||
              c.Office_Name?.toLowerCase().includes(
                sessionStorage.getItem("searchvalueTeam")
              )
          )
      )

      // if (team_filter.length === 0) {
      //   team_filter = data?.allTeams?.edges.filter(list =>
      //     list?.node?.offices?.some(c => c.Branch_Location?.toLowerCase().includes(sessionStorage.getItem("searchvalueTeam")))
      //   )
      // }

      setteams(team_filter)
    } else {
      setteams(data?.allTeams?.edges)
    }
    // setBranch(searchvalue)
    window.scrollTo({
      top: 200,
      left: 0,
      behavior: "smooth",
    })
  }


  const [isPlay, setIsPlay] = useState(false)

  const [teamLocation, setteamLocation] = useState("All Location")
  const teamLocationSelect = e => {
    setsearchvalueTeam("")
    setteamLocation(e)
    // setteamDepartment("All Department")
    setteamBranch("All Branch")
    var locname = e === "All Location" ? "" : e.toLowerCase()

    if (locname) {
      setteams(
        data?.allTeams?.edges?.filter(list =>
          list?.node?.offices?.some(c =>
            c.Branch_Location?.toLowerCase().includes(locname)
          )
        )
      )
    } else {
      setteams(data?.allTeams?.edges)
    }
  }

  const [teambranch, setteamBranch] = useState("All Branch")
  const teambranchSelect = e => {
    setsearchvalueTeam("")
    setteamBranch(e)
    setteamLocation("All Location")
    // setteamDepartment("All Department")
    var brname = e === "All Branch" ? "" : e.toLowerCase()

    if (brname) {
      setteams(
        data?.allTeams?.edges?.filter(list =>
          list?.node?.offices?.some(c =>
            c.Office_Name?.toLowerCase().includes(brname)
          )
        )
      )
    } else {
      setteams(data?.allTeams?.edges)
    }
  }

 

  const seearchonChange = e => {
    setsearchvalue(e.target.value.toLowerCase())
    sessionStorage.setItem("searchvalue", e.target.value.toLowerCase())
    setareas(
      data?.allAreas?.edges?.filter(list =>
        list?.node?.AreaName.toLowerCase().includes(
          e.target.value.toLowerCase()
        )
      )
    )
  }

  

  return (
    <div className={`${isSafari || isMacOs ? "mac-saf" : ""}`}>
      <ScrollingProvider scrollBehavior="smooth">
        {windowWidth > 768 && props.Page?.Pagename !== "Home" && (
          <Breadcrumbs Page={props.Page?.Pagename} alias={props.Page?.Alias} />
        )}
        {props.Page?.Banner_Type === "Team" ? (
          <StaticBannerCenter
            executeScroll={executeScroll}
            imagetransforms={props?.Page?.imagetransforms}
            id={props?.Page?.id}
            Banner_Image={props.Page?.Banner_Image}
            Banner_Content={props.Page?.Banner_Content}
            Banner_Title={props.Page?.Banner_Title}
            Banner_Type={props.Page?.Banner_Type}
            Show_Form={props.Page?.Show_Form}
            Show_Review={props.Page?.Show_Review}
            Pagename={props.Page?.Pagename}
            Cta_Links={props.Page?.Cta_Links}
            areas={areas}
            offices={
              searchvalue
                ? // ? offices.filter(
                  //   list =>
                  //     list.Branch_Location?.toLowerCase().includes(searchvalue) ||
                  //     list.County?.toLowerCase().includes(searchvalue)
                  // )
                  data.glstrapi?.offices.filter(list =>
                    list.choose_areas?.some(c =>
                      c.AreaName?.toLowerCase().includes(
                        sessionStorage.getItem("searchvalue")
                      )
                    )
                  )
                : offices
            }
            setoffices={setoffices}
            officeslist={officeslist}
            showlist={showlist}
            seearchonChange={seearchonChange}
            seearchonClink={seearchonClink}
            onleavesearch={onleavesearch}
            seearchonClose={seearchonClose}
            seearchonLeave={seearchonLeave}
            searchvalue={searchvalue}
            setsearchvalue={setsearchvalue}
            setshowlist={setshowlist}
            showteam={showteam}
            seearchonChangeTeam={seearchonChangeTeam}
            seearchonClinkTeam={seearchonClinkTeam}
            onleavesearchTeam={onleavesearchTeam}
            seearchonCloseTeam={seearchonCloseTeam}
            seearchonLeaveTeam={seearchonLeaveTeam}
            seearchonSubmitTeam={seearchonSubmitTeam}
            searchvalueTeam={searchvalueTeam}
            setsearchvalueTeam={setsearchvalueTeam}
          />
        ) : null}
        {props.Modules && props.Modules?.length > 0
          ? props.Modules.map((Module, i) => {
              return (
                <div key={i}>
                  
               
                  {Module.Choose_Collection === "teams" && (
                    <>
                      <FilterBar
                        branchlist={data.glstrapi?.offices}
                        teamLocationSelect={teamLocationSelect}
                        teamLocation={teamLocation}
                        teambranchSelect={teambranchSelect}
                        teambranch={teambranch}
                        // teamDepartmentSelect={teamDepartmentSelect}
                        // teamDepartment={teamDepartment}
                        teamcount={teams?.length}
                        departments={departments}
                      />
                      <PeopleList
                        teamLocationSelect={teamLocationSelect}
                        teamLocation={teamLocation}
                        teambranchSelect={teambranchSelect}
                        teambranch={teambranch}
                        // teamDepartmentSelect={teamDepartmentSelect}
                        // teamDepartment={teamDepartment}
                        teams={teams ? teams : []}
                        teamcount={teams?.length}
                      />
                    </>
                  )}

                
                </div>
              )
            })
          : ""}
      </ScrollingProvider>
    </div>
  )
}
export default Modules
