import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
//import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/DNGResidential_1.jpg"
import Image2 from "../../../images/DNGResidential_2.jpg"
import Image3 from "../../../images/DNGResidential_3.jpg"
import Image4 from "../../../images/DNGResidential_4.jpg"
import NoImage from "../../../images/no-image.png"
import contactImg1 from "../../../images/analysis_member.jpg"
import "./Videos.scss"
import ReactMarkdown from "react-markdown"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import Youtube from "./../../SearchResults/Youtube/Youtube"
import moment from "moment"
import PlayVideo from "../../PlayVideo/PlayVideo"
import OpinionListGridVideo from "./../../../Components/Home/Opinion/OpinionList/OpinionListGridVideo"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const VideosCom = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        blogCategories {
          Category_Name
        }
        videos(
          sort: "Date:desc"
          publicationState: LIVE
          where: { Publish: true }
        ) {
          id
          imagetransforms
          Publish
          Title
          Video_Url
          Date
          video_categories {
            Category_Name
          }
          Image {
            alternativeText
            url
          }
        }
      }
    }
  `)

  const [isPlay, setIsPlay] = useState(false)
  const [video_url, setVideoURL] = useState(-1)

  const playVideoHandler = video_url => {
    setIsPlay(true)
    setVideoURL(video_url)
  }

  const [videos, setvideos] = useState(data.glstrapi?.videos)
  const [filter, setFilter] = useState("All")
  const clickFilter = e => {
    setFilter(e)
    var arr = []
    data.glstrapi?.videos
      .filter(list =>
        list.video_categories.map(c => c.Category_Name).includes(e)
      )
      .map((item, index) => {
        arr.push(item)
      })
    setvideos(e === "All" ? data.glstrapi?.videos : arr)
  }
  var names = data.glstrapi?.blogCategories

  const postsPerPage = 6
  let arrayForHoldingPosts = []
  // const [] = useState(0);
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(6)
  const loopWithSlice = (start, end) => {
    const slicedPosts = videos?.slice(0, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    loopWithSlice(
      postsToShow?.length > 14 ? next : 14,
      postsToShow?.length > 14 ? next + postsPerPage : 14 + postsPerPage
    )
    setNext(postsToShow?.length > 14 ? next + postsPerPage : 14 + postsPerPage)
  }

  useEffect(() => {
    loopWithSlice(0, 14)
    setNext(6)
  }, [filter])
  // const AnalysisLest = [
  // 	{
  // 		Images: Image1,
  // 		Date: '06 May 2021',
  // 		Heading: 'DNG Residential Market Review Q1 2021',
  // 	},
  // 	{
  // 		Images: Image2,
  // 		Date: '06 May 2021',
  // 		Heading: 'DNG Residential Market Review Q4 2020',
  // 	},
  // 	{
  // 		Images: Image3,
  // 		Date: '06 May 2021',
  // 		Heading: 'DNG Residential Market Review Q3 2020',
  // 	},
  // 	{
  // 		Images: Image4,
  // 		Date: '06 May 2021',
  // 		Heading: 'DNG Residential Market Review Q2 2020',
  // 	},
  // ]
  const ImageRender = ({
    alt,
    image_url,
    node,
    processedImages,
    imagename,
  }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename={imagename}
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }
  return (
    <div className="videos-block opinion">
      <Container>
        <Row>
          <Col sm="12" lg="8">
            {props?.Title && (
              <motion.div variants={fadeInFromLeft}>
                <ReactMarkdown source={props?.Title} allowDangerousHtml />
              </motion.div>
            )}
            {props?.Content && (
              <motion.div variants={fadeInFromLeft}>
                <ReactMarkdown source={props?.Content} allowDangerousHtml />
              </motion.div>
            )}
          </Col>
          <Col sm={12}>
            <motion.div
              className="fiter-news-categories"
              variants={fadeInFromLeft}
            >
              <ul>
                <li
                  onClick={() => clickFilter("All")}
                  className={
                    filter === "All"
                      ? "all-news active-category list-tab"
                      : "all-news list-tab"
                  }
                >
                  All Insights
                </li>
                {names.map(list =>
                  list.Category_Name === "Videos" ? (
                    ""
                  ) : (
                    <li
                      onClick={() => clickFilter(list.Category_Name)}
                      className={
                        filter === list.Category_Name
                          ? "active-category list-tab"
                          : "list-tab"
                      }
                    >
                      {list.Category_Name}
                    </li>
                  )
                )}
              </ul>
            </motion.div>
          </Col>
        </Row>
        <Row className="row-padding">
          {/* <div className='opinion'>
				<OpinionListGridVideo news={videos?.slice(2, videos?.length)} />
				</div> */}
          {postsToShow && postsToShow.length > 0 ? (
            <>
              {postsToShow?.slice(0, 2).map((item, index) => {
                let processedImages = JSON.stringify({})
                if (item?.imagetransforms?.Image_Transforms) {
                  processedImages = item.imagetransforms.Image_Transforms
                }
                return (
                  <>
                    <Col md="6" lg="6">
                      <div className="advantage-item">
                        <picture>
                          {item.Video_Url && (
                            <div className="btn-wrapper">
                              <button
                                type="button"
                                onClick={() => playVideoHandler(item.Video_Url)}
                              >
                                <i className="icon-play"></i>
                              </button>
                            </div>
                          )}
                          {/* <img src={item.Image?.url} alt={item.Image?.alternativeText} /> */}
                          {item?.Image?.url ? (
                            <ImageRender
                              imagename="video.Image.detail"
                              alt={
                                item.Image?.alternativeText
                                  ? item.Image?.alternativeText
                                  : item.Title + " - DNG Estate Agents"
                              }
                              processedImages={processedImages}
                              image_url={item?.Image?.url}
                              node={item}
                            />
                          ) : (
                            <img
                              src={NoImage}
                              alt={item.Image?.alternativeText}
                            />
                          )}
                        </picture>
                        <div className="advantage-info">
                          <span className="date-text">
                            {moment(item?.Date).format("DD MMMM YYYY")}
                          </span>
                          <h3 className="max">
                            <Link to={item.URL}>{item.Title}</Link>
                          </h3>
                        </div>
                      </div>
                    </Col>
                  </>
                )
              })}
              {postsToShow?.slice(2, postsToShow?.length).map((item, index) => {
                let processedImages = JSON.stringify({})
                if (item?.imagetransforms?.Image_Transforms) {
                  processedImages = item.imagetransforms.Image_Transforms
                }
                return (
                  <>
                    {index % 6 === 0 && index !== 0 && <Youtube />}
                    <Col md="6" lg="4">
                      <div className="advantage-item">
                        <picture
                          onClick={() => playVideoHandler(item.Video_Url)}
                        >
                          {item.Video_Url && (
                            <div className="btn-wrapper">
                              <button type="button">
                                <i className="icon-play"></i>
                              </button>
                            </div>
                          )}
                          {/* <img src={item.Image?.url} alt={item.Image?.alternativeText} /> */}
                          {item?.Image?.url ? (
                            <ImageRender
                              imagename="video.Image.tail"
                              alt={
                                item.Image?.alternativeText
                                  ? item.Image?.alternativeText
                                  : item.Title + " - DNG Estate Agents"
                              }
                              processedImages={processedImages}
                              image_url={item?.Image?.url}
                              node={item}
                            />
                          ) : (
                            <img
                              src={NoImage}
                              alt={item.Image?.alternativeText}
                            />
                          )}
                        </picture>
                        <div className="advantage-info">
                          <span className="date-text">
                            {moment(item?.Date).format("DD MMMM YYYY")}
                          </span>
                          <h3>
                            <Link to={item.URL}>{item.Title}</Link>
                          </h3>
                        </div>
                      </div>
                    </Col>
                  </>
                )
              })}
            </>
          ) : (
            <Col sm="12" lg="8">
              <div>No videos found</div>
            </Col>
          )}
        </Row>
      </Container>
      {postsToShow?.length !== videos?.length && (
        <Container>
          <Row>
            <Col md="12">
              <div className="review-load">
                <button
                  className="btn-pagination btn btn-primary"
                  onClick={handleShowMorePosts}
                >
                  Load more
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <PlayVideo
        isOpen={isPlay}
        stopPlay={setIsPlay}
        videoId={video_url}
        isAutoPlay={1}
      />
    </div>
  )
}

export default VideosCom
