import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
//import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/DNGResidential_1.jpg"
import Image2 from "../../../images/DNGResidential_2.jpg"
import Image3 from "../../../images/DNGResidential_3.jpg"
import Image4 from "../../../images/DNGResidential_4.jpg"
import NoImage from "../../../images/no-image.png"
import contactImg1 from "../../../images/analysis_member.jpg"
import "./Express.scss"
import ReactMarkdown from "react-markdown"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"
import Valuation from "./../../../Components/SearchResults/Valuation/Valuation"
import PlayVideo from "../../PlayVideo/PlayVideo"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const Express = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        expresses(
          sort: "Date:desc"
          publicationState: LIVE
          where: { Publish: true }
        ) {
          id
          imagetransforms
          Title
          Tail_Image {
            url
            alternativeText
          }
          Date
          URL
        }
      }
    }
  `)

  const [expresses, setexpresses] = useState(data.glstrapi?.expresses)
  const postsPerPage = 8
  let arrayForHoldingPosts = []
  // const [] = useState(0);
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(8)
  const loopWithSlice = (start, end) => {
    const slicedPosts = expresses?.slice(0, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    loopWithSlice(
      postsToShow?.length > 16 ? next : 16,
      postsToShow?.length > 16 ? next + postsPerPage : 16 + postsPerPage
    )
    setNext(postsToShow?.length > 16 ? next + postsPerPage : 16 + postsPerPage)
  }

  useEffect(() => {
    loopWithSlice(0, 16)
  }, [])
  const AnalysisLest = [
    {
      Images: Image1,
      Date: "06 May 2021",
      Heading: "DNG Residential Market Review Q1 2021",
    },
    {
      Images: Image2,
      Date: "06 May 2021",
      Heading: "DNG Residential Market Review Q4 2020",
    },
    {
      Images: Image3,
      Date: "06 May 2021",
      Heading: "DNG Residential Market Review Q3 2020",
    },
    {
      Images: Image4,
      Date: "06 May 2021",
      Heading: "DNG Residential Market Review Q2 2020",
    },
  ]
  const ImageRender = ({ alt, image_url, node, processedImages }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="express.Tail_Image.tail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }
  return (
    <div className="express-block">
      <Container>
        <Row>
          <Col sm="12" lg="8">
            {props?.Title && (
              <motion.div variants={fadeInFromLeft}>
                <ReactMarkdown source={props?.Title} allowDangerousHtml />
              </motion.div>
            )}
            {props?.Content && (
              <motion.div variants={fadeInFromLeft}>
                <ReactMarkdown source={props?.Content} allowDangerousHtml />
              </motion.div>
            )}
          </Col>
        </Row>
        <Row className="col-wrap row-padding">
          {postsToShow.map((item, index) => {
            let processedImages = JSON.stringify({})
            if (item?.imagetransforms?.Tail_Image_Transforms) {
              processedImages = item.imagetransforms.Tail_Image_Transforms
            }
            return (
              <>
                {index % 8 === 0 && index !== 0 && <Valuation />}
                <Col md="6" lg="3">
                  <div className="advantage-item">
                    <Link to={"/services/sellers/dng-express/"+ item.URL}>
                      <picture>
                        {item?.Tail_Image?.url ? (
                          //   <ImageRender
                          //     alt={item.Tail_Image?.alternativeText}
                          //     processedImages={processedImages}
                          //     image_url={item?.Tail_Image?.url}
                          //     node={item}
                          <img
                            src={item?.Tail_Image?.url}
                            alt={
                              item.Tail_Image?.alternativeText
                                ? item.Tail_Image?.alternativeText +
                                  " - DNG Estate Agents"
                                : item.Title + " - DNG Estate Agents"
                            }
                          />
                        ) : (
                          <img
                            src={NoImage}
                            alt={item.Tail_Image?.alternativeText}
                          />
                        )}
                        {/* <img src={item.Tail_Image?.url} alt={item.Tail_Image?.alternativeText} /> */}
                      </picture>
                    </Link>
                    <div className="advantage-info">
                      <span className="date-text">
                        {moment(item?.Date).format("DD MMMM YYYY")}
                      </span>
                      <h3>
                        <Link to={"/services/sellers/dng-express/"+ item.URL}>{item.Title}</Link>
                      </h3>
                    </div>
                  </div>
                </Col>
              </>
            )
          })}
        </Row>
      </Container>
      {postsToShow?.length !== expresses?.length && (
        <Container>
          <Row>
            <Col md="12">
              <div className="review-load">
                <button
                  className="btn-pagination btn btn-primary"
                  onClick={handleShowMorePosts}
                >
                  Load more
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default Express
